import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root",
})
export class RepositoryCampaignService {
  getForCsr(campaignId: number) {
    return this.http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.campaign
      )}GetForCsr/${campaignId}`
    );
  }
  getWhiteMail() {
    return this.http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.campaign
      )}WhiteMailCampaign`
    );
  }

  constructor(
    private _commonService: CommonService,
    private http: HttpClient
  ) {}
}
