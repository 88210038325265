import { Component, Input } from "@angular/core";
import { CsrService } from "../csr/csr.service";
import { CommonService } from "../common/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-bar",
  templateUrl: "./header-bar.component.html",
  styleUrls: ["./header-bar.component.scss"],
})
export class HeaderBarComponent {
  @Input() isHidden = false;

  constructor(private csrService: CsrService,private _commonService: CommonService,private router : Router) {}

  getCampaignId(): number | null {
    return this.csrService.getCampaignId();
  }

  aboutClicked() {
    this._commonService.aboutShuffel();
    setTimeout(()=>{
      this.router.navigate(['/about']);
    },1000)
  }

  contactClicked() {
    this._commonService.contactShuffel();
    setTimeout(()=>{
      this.router.navigate(['/contact']);
    },1000)
  }

  homeClicked() {
    this._commonService.homeShuffel();
    setTimeout(()=>{
      this.router.navigate(['/splash', this.getCampaignId()]);
    },1000)
  }
}
