
<form #honorForm="ngForm" class="row" style="margin-bottom: 20px;">
  <div class="col-sm-12">
    <div class="well" style="border: solid 1px #ccc; box-shadow: 0 0 5px 2px #ddd;">

      <div class="row">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-8 col-sm-12">

          <div class="row">
            <div class="col-sm-12">
              <div style="margin-top: 10px; text-align: left;">
                <div class="well well-sm"
                     (click)="inMemoryHonorOf=!inMemoryHonorOf;"
                     style="color: #333; background: #ddd; position: relative; padding-left: 45px; cursor: pointer; margin-bottom: 10px;">
                  <button type="button" class="btn btn-sm donation-checkbox"
                          style="color: #333; font-size: 14px; position: absolute; top: 4px; left: 9px;">
                    <i class="fa fa-1-5x" [ngClass]="{'fa-check-square-o':inMemoryHonorOf,'fa-square-o':!inMemoryHonorOf}"></i>
                  </button>
                  <strong style="text-transform: unset !important; ">
                    {{'SPLASH_IN_MEMORY_1' | translate}}
                  </strong>
                  <span style="display: block; font-size: 12px;">
                    {{'SPLASH_IN_MEMORY_2' | translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- hasErrors: {{hasErrors}} -->

          <section *ngIf="inMemoryHonorOf" class="well well-sm" style="background:#eee; box-shadow: 0 0 1px 1px #ddd;">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div style="margin-top: 10px; text-align: left;">
                  <div class="well well-sm"
                       (click)="inHonor=!inHonor;inMemory=false;"
                       style="color: #333; background: #ddd; position: relative; padding-left: 45px; cursor: pointer; margin-bottom: 10px;">
                    <button type="button" class="btn btn-sm donation-checkbox"
                            style="color: #333; font-size: 14px; position: absolute; top: 4px; left: 9px;">
                      <i class="fa fa-1-5x" [ngClass]="{'fa-check-square-o':inHonor,'fa-square-o':!inHonor}"></i>
                    </button>
                    <strong style="text-transform: unset !important; ">
                      {{'IN_HONOR' | translate}}
                    </strong>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div style="margin-top: 10px; text-align: left;">
                  <div class="well well-sm"
                       (click)="inMemory=!inMemory;inHonor=false"
                       style="color: #333; background: #ddd; position: relative; padding-left: 45px; cursor: pointer; margin-bottom: 10px;">
                    <button type="button" class="btn btn-sm donation-checkbox"
                            style="color: #333; font-size: 14px; position: absolute; top: 4px; left: 9px;">
                      <i class="fa fa-1-5x" [ngClass]="{'fa-check-square-o':inMemory,'fa-square-o':!inMemory}"></i>
                    </button>
                    <strong style="text-transform: unset !important; ">
                      {{'IN_MEMORY' | translate}}
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group text-left" [ngClass]="showError(inhonorname) ? (inhonorname.valid ? 'has-success': 'has-error') : ''">
                  <label for="inhonorname">{{'IN_HONOR_NAME' | translate}}</label><br />
                  <input type="text"
                         id="inhonorname"
                         name="inhonorname"
                         #inhonorname="ngModel"
                         [(ngModel)]="honorModel.inHonorName"
                         class="form-control form-control-required"
                         maxlength="50"
                         required="" />
                  <span *ngIf="inhonorname.invalid && showError(inhonorname)" class="help-block has-error error-msg">This field is required</span>
                </div>
              </div>
            </div>
          </section>

          <div class="row">
            <div class="col-sm-12">
              <div style="border-bottom: solid 1px #ccc; margin: 20px 50px;"></div>
            </div>
            <div class="col-sm-12">
              <div style="margin-top: 10px; text-align: left;">
                <div class="well well-sm"
                     (click)="notifySomeone=!notifySomeone;"
                     style="color: #333; background: #ddd; position: relative; padding-left: 45px; cursor: pointer; margin-bottom: 10px;">
                  <button type="button" class="btn btn-sm donation-checkbox"
                          style="color: #333; font-size: 14px; position: absolute; top: 4px; left: 9px;">
                    <i class="fa fa-1-5x" [ngClass]="{'fa-check-square-o':notifySomeone,'fa-square-o':!notifySomeone}"></i>
                  </button>
                  <strong style="text-transform: unset !important; ">
                    {{'NOTIFY' | translate}}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12"></div>
      </div>

      <section *ngIf="notifySomeone" style="padding-top: 20px;">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group text-left" [ngClass]="showError(notifyname) ? (notifyname.valid ? 'has-success': 'has-error') : ''">
              <label for="notifyname">{{'NOTIFY_NAME' | translate}}</label><br />
              <input type="text"
                     id="notifyname"
                     name="notifyname"
                     #notifyname="ngModel"
                     [(ngModel)]="giftNotify.notifyName"
                     class="form-control form-control-required"
                     maxlength="50"
                     required="" />
              <span *ngIf="notifyname.invalid && showError(notifyname)" class="help-block has-error error-msg">This field is required</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group text-left" [ngClass]="showError(notifyemail) ? (notifyemail.valid ? 'has-success': 'has-error') : ''">
              <label for="notifyemail">{{'EMAIL' | translate}}</label><br />
              <input type="email"
                     id="notifyemail"
                     name="notifyemail"
                     #notifyemail="ngModel"
                     [(ngModel)]="giftNotify.email"
                     class="form-control form-control-required"
                     maxlength="128"
                     required=""
                     [email]="true" />
              <span *ngIf="notifyemail.invalid && showError(notifyemail) && notifyemail?.errors?.required" class="help-block has-error error-msg">
                This field is required
              </span>
              <span *ngIf="notifyemail.invalid && showError(notifyemail) && notifyemail?.errors?.email" class="help-block has-error error-msg">
                Please enter a valid email address
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-group text-left" [ngClass]="showError(notifytelephone) ? (notifytelephone.valid ? 'has-success': 'has-error') : ''">
              <label for="notifytelephone">{{'TELEPHONE' | translate}}</label><br />
              <input type="tel"
                     id="notifytelephone"
                     name="notifytelephone"
                     #notifytelephone="ngModel"
                     [(ngModel)]="giftNotify.telephone"
                     class="form-control form-control-required"
                     required minlength="7" maxlength="14" pattern="^[0-9]+$" />

                    <span *ngIf="notifytelephone.invalid && showError(notifytelephone) && notifytelephone?.errors?.required"
                        class="help-block has-error error-msg">This field is required</span>
                    <span *ngIf="notifytelephone.invalid && showError(notifytelephone) && (!notifytelephone?.errors?.pattern && notifytelephone?.errors?.minlength)"
                        class="help-block has-error error-msg">Please enter at least 7 characters</span>
                    <span *ngIf="notifytelephone.value?.length === 14 && (notifytelephone.dirty || notifytelephone.touched)"
                        class="help-block has-error error-msg">Characters limit for this input was reached.</span>
                    <span *ngIf="notifytelephone.invalid && showError(notifytelephone) && notifytelephone?.errors?.pattern"
                        class="help-block has-error error-msg">Only numbers are allowed</span>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-sm-6">
            <div class="form-group text-left" [ngClass]="showError(postalCodeNotify) ? (postalCodeNotify.valid ? 'has-success': 'has-error') : ''">
              <label for="postalCodeNotify">{{'POSTAL_CODE' | translate}}</label>
              <div class="input-group" style="margin-top: 10px;">
                <input type="text"
                       id="postalCodeNotify"
                       name="postalCodeNotify"
                       #postalCodeNotify="ngModel"
                       [(ngModel)]="giftNotify.postalCode"
                       class="form-control form-control-required"
                       style="margin-top: 0;"
                       (blur)="donorEditCommon.lookUpZipBlur(giftNotify.postalCode, giftNotify)"
                       maxlength="15"
                       required="" />
                <span *ngIf="donorEditCommon.lookingUpZip" class="input-group-addon">
                  <i class="fa fa-spin fa-spinner text-warning"></i>
                </span>
                <span *ngIf="!donorEditCommon.lookingUpZip" class="input-group-addon">
                  <i class="glyphicon glyphicon-search "></i>
                </span>
              </div>
              <span *ngIf="postalCodeNotify.invalid && showError(postalCodeNotify)" class="help-block has-error error-msg">This field is required</span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group text-left" [ngClass]="showError(notifyaddress1) ? (notifyaddress1.valid ? 'has-success': 'has-error') : ''">
              <label for="notifyaddress1">{{'ADDRESS_1' | translate}}</label><br />
              <input type="text"
                     id="notifyaddress1"
                     name="notifyaddress1"
                     #notifyaddress1="ngModel"
                     [(ngModel)]="giftNotify.address1"
                     class="form-control form-control-required"
                     maxlength="50"
                     required="" />
              <span *ngIf="notifyaddress1.invalid && showError(notifyaddress1)" class="help-block has-error error-msg">This field is required</span>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group text-left" [ngClass]="showError(notifyaddress2) ? (notifyaddress2.valid ? 'has-success': 'has-error') : ''">
              <label for="notifyaddress2">{{'ADDRESS_2' | translate}}</label><br />
              <input type="text"
                     id="notifyaddress2"
                     name="notifyaddress2"
                     #notifyaddress2="ngModel"
                     [(ngModel)]="giftNotify.address2"
                     class="form-control"
                     maxlength="50" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-sm-6">
            <div class="form-group text-left" [ngClass]="showError(notifycity) ? (notifycity.valid ? 'has-success': 'has-error') : ''">
              <label for="notifycity">{{'CITY' | translate}}</label><br />
              <input type="text"
                     id="notifycity"
                     name="notifycity"
                     #notifycity="ngModel"
                     [(ngModel)]="giftNotify.city"
                     class="form-control form-control-required"
                     maxlength="50"
                     required="" />
              <span *ngIf="notifycity.invalid && showError(notifycity)" class="help-block has-error error-msg">This field is required</span>
            </div>
          </div>
          <div class="col-sm-12 col-sm-6">
            <div class="form-group text-left" [ngClass]="showError(notifystateId) ? (notifystateId.valid ? 'has-success': 'has-error') : ''">
              <label for="notifystateId">{{'STATE' | translate}}</label><br />
              <select name="notifystateId"
                      #notifystateId="ngModel"
                      [(ngModel)]="giftNotify.stateId"
                      class="form-control form-control-required"
                      style="margin-top: 10px;"
                      required="">
                <option value="">--Select State--</option>
                <option *ngFor="let ctype of donorEditCommon.states" [ngValue]="ctype.value">{{ctype.label}}</option>
              </select>
              <span *ngIf="notifystateId.invalid && showError(notifystateId)" class="help-block has-error error-msg">This field is required</span>
            </div>
          </div>
        </div>
      </section>
      <div style="clear: both;">&nbsp;&nbsp;&nbsp;</div>
    </div>
  </div>
</form>
