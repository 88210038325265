<section class="animation-effects" [ngClass]="{'animate' : showAnimation}">
  <div class="row">
    <div class="col-sm-12">
      <p>If you have any questions, please feel free to contact us at:</p>
      <p>
        <strong>888-209-7905</strong>
      </p>
    </div>
  </div>
</section>
