import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../base.component";
import { CommonService } from "../common/common.service";
import { CsrService } from "../csr/csr.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-drtv-menu",
  templateUrl: "./drtv-menu.component.html",
  styleUrls: ["./drtv-menu.component.scss"],
})
export class DrtvMenuComponent extends BaseComponent {
  @Input() sticky = true;

  campaignId = this._csrService.getCampaignId();
  hospitalId = this._csrService.getHospitalId();

  private _phoneNumber = "18007690995";

  donationProcessed: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _csrService: CsrService,
    private _googleAnalytics: GoogleAnalyticsService,
    private _commonService: CommonService
  ) {
    super();
  }

  protected _afterComponentInit(): void {

    const phoneParam = this._route.snapshot.queryParamMap
      .get("phone")
      ?.replace(/\D/g, "");

    if (phoneParam?.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/m)) {
      this._phoneNumber = phoneParam
    }

    this.addSubscription(
      this._commonService.donationProcessed$.subscribe(value => {
        this.donationProcessed = value;
      })
    )
  }
  protected _afterComponentDestroy(): void {}

  get phoneNumber() {
    return this._formatPhoneNumber();
  }

  private _formatPhoneNumber() {
    if (this._phoneNumber.length === 10) {
      return this._phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }

    return this._phoneNumber.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3-$4"
    );
  }

  gaDonateClick(eventCategory: string) {
    this._googleAnalytics.customEventEmitter({
      eventName: "donate_button",
      eventCategory,
      eventAction: "click",
    });
  }

  scrollToDonate(e: Event) : void {
    e.preventDefault();
    const element = document.getElementById("donateNow");
    if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
    if(this.donationProcessed) {
      this._commonService.showConfirmCreditCardECheckPaymentModal(true);
    }
  }
}
