import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { forkJoin, Observable, combineLatest, pipe, of } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";

import { CommonService } from "../common/common.service";
import { EventBusService, EventsEnum } from "../common/event-bus.service";
import { LanguageService } from "../common/language.service";
import { LocalStorageService } from "../common/local-storage.service";
import { LoggerService } from "../common/logger.service";
import { ConfigService } from "../config.service";
import { CsrService } from "../csr/csr.service";
import { ChargeFrequencyEnum } from "../domain/enums";
import { ICampaignBindingModel, IHospitalBindingModel } from "../domain/types";
import { DataService } from "../services/data.service";

interface ISplashRouteParams {
  campaignId?: string | null;
  hospitalId?: string | null;
  radioStationId?: string | null;
  yes?: string | null;
  amount?: string | null;
  recurring?: string | null;
}

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  showPage = false;
  enableDonation = true;
  imageUrl = "";
  hideImage = false;
  private _routeParams: ISplashRouteParams = {
    campaignId: null,
    hospitalId: null,
    radioStationId: null,
    yes: null,
    amount: null,
    recurring: null,
  };
  showAnimation: boolean = false;

  constructor(
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _events: EventBusService,
    private _csrService: CsrService,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _dataService: DataService,
    private _configService: ConfigService,
    private _loggerService: LoggerService,
    private _languageService: LanguageService
  ) {
    this._route.data.subscribe((data) => {
      this._events.emit({ name: EventsEnum.RouteChanged, data });
    });

    this._commonService.pageTitle = "";
    this._commonService.pageSubTitle = "";
    this._commonService.shuffelAbout.subscribe(() => {
      this.showAnimation = true;
    });
    this._commonService.shuffelContact.subscribe(() => {
      this.showAnimation = true;
    });
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((paramsMap) => {
      this._routeParams.campaignId = paramsMap.get("cid");
      this._routeParams.hospitalId = paramsMap.get("hid");
      this._routeParams.radioStationId = paramsMap.get("rid");
      this._routeParams.yes = paramsMap.get("yes");

      this._commonService.activateController([], "home").then(() => {
        this._csrService.initialize();

        if (
          this._routeParams.hospitalId ||
          this._routeParams.campaignId ||
          this._routeParams.radioStationId
        ) {
          try {
            if (this._routeParams.campaignId) {
              this._csrService.setCampaignId(
                Number(this._routeParams.campaignId)
              );
              this._localStorageService.set(
                "lastCampaignId",
                this._csrService.getCampaignId()
              );
            }

            if (this._routeParams.hospitalId) {
              this._csrService.setHospitalId(
                Number(this._routeParams.hospitalId)
              );
              this._localStorageService.set(
                "lastHospitalId",
                this._csrService.getHospitalId()
              );
            } else {
              this._csrService.setHospitalId(0);
              this._localStorageService.set("lastHospitalId", 0);
            }

            if (this._routeParams.radioStationId) {
              this._csrService.radioStationId = Number(
                this._routeParams.radioStationId
              );
              this._localStorageService.set(
                "lastRadioStationId",
                this._csrService.radioStationId
              );
            } else {
              this._csrService.radioStationId = 0;
              this._localStorageService.set("lastRadioStationId", 0);
            }

            this._getHospitalCampaignRadioStation();
          } catch (e) {
            this.enableDonation = false;
          }
        } else {
          if (this._csrService.getCampaignId() === 0) {
            this.enableDonation = false;
          } else {
            this._getHospitalCampaignRadioStation();
          }
        }
      });
    });
  }

  private _getHospitalCampaignRadioStation(): void {
    let requests: Observable<any>[] = [this._getCampaignForCsr()];

    if (this._csrService.getHospitalId() !== 0) {
      requests.push(this._getHospital());
    }
    if (this._csrService.radioStationId !== 0) {
      requests.push(this._getRadioStation());
    }

    this._events.emit({ name: EventsEnum.SpinnerToggle, data: true });

    combineLatest(requests).subscribe(
      (responses) => {
        this._events.emit({ name: EventsEnum.SpinnerToggle, data: false });

        if (this._routeParams.yes === "y") {
          if (this._routeParams.amount) {
            this._csrService.donationAmount = Number(this._routeParams.amount);
          }

          if (this._routeParams.recurring === "y") {
            this._csrService.chargeFrequency = ChargeFrequencyEnum.Monthly;
          }

          this._router.navigate(["/getdonor"]);
        } else {
          this.showPage = true;
        }
      },
      () => {
        this._events.emit({ name: EventsEnum.SpinnerToggle, data: false });
      }
    );
  }

  private _getHospital(): Observable<IHospitalBindingModel> {
    return this._dataService.getHospital(this._csrService.getHospitalId()).pipe(
      map((hospital) => {
        this._csrService.setHospitalName(hospital.hospitalName);

        return hospital;
      }),
      catchError((error) => {
        const htmlMessage = this._commonService.jsonMessage(error);

        this._loggerService.logError({
          message: `
                  Could not get the Hospital
                  <br />
                  ${htmlMessage}
                `,
        });

        this.enableDonation = false;

        throw error;
      })
    );
  }

  private _getCampaignForCsr(): Observable<ICampaignBindingModel> {
    const campaignId = this._csrService.getCampaignId();

    return this._dataService.getCampaignForCsr(campaignId).pipe(
      map((campaign) => {
        // TODO: do we need this line?
        // this._common.globalData.languageValue = campaign.languageValue;
        this._events.emit({
          name: EventsEnum.LanguageChanged,
          data: campaign.languageValue,
        });

        this.toggleImage(true);
        this.imageUrl =
          this._commonService.serviceUrl(
            this._configService.apiServices.campaign
          ) +
          "SplashImage/" +
          campaignId;

        return campaign;
      }),
      catchError((error) => {
        const htmlMessage = this._commonService.jsonMessage(error);
        this._loggerService.logError({
          message: `
                  Could not get the Campaign
                  <br />
                  ${htmlMessage}
                `,
        });

        this.enableDonation = false;

        throw error;
      })
    );
  }

  private _getRadioStation() {
    return this._dataService
      .getRadioStation(this._csrService.radioStationId)
      .pipe(
        map((radioStation) => {
          return radioStation; // original code did nothing here
        }),
        catchError((error) => {
          const htmlMessage = this._commonService.jsonMessage(error);
          this._loggerService.logError({
            message: `
                    Could not get the Radio Station
                    <br />
                    ${htmlMessage}
                  `,
          });

          this.enableDonation = false;

          throw error;
        })
      );
  }

  donate() {
    this._router.navigate(["/donate"]);
  }

  toggleImage(shown) {
    this.hideImage = !shown;
  }
}
