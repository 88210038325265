import { AfterContentInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appAutofocus]",
})
export class AutofocusDirective implements AfterContentInit {
  @Input() focus: boolean;

  constructor(private element: ElementRef) {}

  ngAfterContentInit(): void {
    window.setTimeout(() => {
      this.element.nativeElement.focus();
    }, 500);
  }
}
