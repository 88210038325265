import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  get<T>(storageKey: string, defaultValue: T): T {
    return $.jStorage.get(storageKey, defaultValue);
  }

  set<T>(storageKey: string, value: any, options?: JStorageOptions): T {
    var ret = $.jStorage.set(storageKey, value);
    if (options) {
      $.jStorage.setTTL(storageKey, options.TTL);
    }
    return ret;
  }
}
