import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root",
})
export class RepositoryLookupService {
  salutations(languageValue: string) {
    return this._http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.lookup
      )}Salutations/${languageValue}`
    );
  }
  suffixes(languageValue: string) {
    return this._http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.lookup
      )}Suffixes/${languageValue}`
    );
  }
  states() {
    return this._http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.lookup
      )}States`
    );
  }
  sendAppealsTypes(arg0: boolean) {
    return this._http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.lookup
      )}SendAppealsTypes`
    );
  }
  languages() {
    return this._http.get(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.lookup
      )}Languages`
    );
  }

  constructor(
    private _commonService: CommonService,
    private _http: HttpClient
  ) {}
}
