<footer class="drtv-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="footer-container">
          <div class="footer-flex">
            <div class="country-switcher">
              <img
                src="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/canada.svg"
                alt="Canadian Flag"
              />
              <a href="https://childrenshospitals.donordrive.com/index.cfm?fuseaction=donate.event&eventID=525"
                >Are you in Canada? Click here to give to Children's
                Miracle Network Canada.</a
              >
            </div>

            <nav class="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://childrensmiraclenetworkhospitals.org/privacy-policy/"
                    rel="noopener noreferrer"
                    >Privacy Policy</a
                  >
                </li>
                <li>
                  <a
                    href="https://childrensmiraclenetworkhospitals.org/community-standards-en/"
                    rel="noopener noreferrer"
                    >Community Standards</a
                  >
                </li>
                <li>
                  <a href="mailto:donorservices@cmnhospitals.org">Contact</a>
                </li>
              </ul>
            </nav>
          </div>
          <address class="footer-address">
            <a
              href="https://goo.gl/maps/Ju3HywiogmK2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                class="map-marker"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 28"
              >
                <path
                  d="M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zm4 0c0 .953-.109 1.937-.516 2.797L9.796 24.891C9.468 25.579 8.749 26 7.999 26s-1.469-.422-1.781-1.109L.515 12.797C.109 11.938-.001 10.953-.001 10c0-4.422 3.578-8 8-8s8 3.578 8 8z"
                ></path>
              </svg>
              205 West 700 South Salt Lake City, UT 84101
            </a>
          </address>
          <p>
            © {{ currentYear }} Children’s Miracle Network Hospitals, a
            not-for-profit, section 501(c)(3)
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
