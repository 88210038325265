<div [ngClass]="{drtv: drtv}">
  <div id="splash-page" [hidden]="!showSplash" class="dissolve-animation">
    <div class="page-splash">
      <div class="page-splash-message">My Gift for Kids</div>
      <div class="progress progress-striped active page-progress-bar">
        <div class="bar"></div>
      </div>
    </div>
  </div>

  <app-header-bar [isHidden]="hideHeader"></app-header-bar>

  <div [ngClass]="{'body-offsets container': !drtv}" style="min-height: 200px">
    <!-- <div ng-view="" class="shuffle-animation"></div> -->
    <!-- leaving comment here until we implement the shuffle animation -->
    <router-outlet></router-outlet>
  </div>

  <nav *ngIf="!drtv" class="navbar navbar-inverse navbar-fixed-bottom hidden-xs">
    <app-footer-bar></app-footer-bar>
  </nav>

  <nav *ngIf="!drtv" class="navbar navbar-inverse navbar-fixed-bottom visible-xs">
    <app-footer-bar></app-footer-bar>
  </nav>

  <app-spinner></app-spinner>
</div>
