<section id="faqs" #faqs class="drtv-faqs">
  <div class="drtv-faqs__blue-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Children's Miracle Network Hospitals FAQs</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="drtv-faqs__faqs">
          <app-drtv-faq [defaultOpen]="true">
            <strong>Which hospital will my donation benefit?</strong>

            <p answer>
              In communities across the U.S., 146 member hospitals help kids
              have a healthier future. Please input your billing zip code to
              find the Children’s Miracle Network Hospital member hospital
              closest to you:
            </p>
            <div class="hospital-search" answer>
              <app-hospital-search></app-hospital-search>
            </div>
          </app-drtv-faq>

          <app-drtv-faq *ngFor="let question of this.campaignQuestions">
            {{ question.question }}

            <p answer>
              {{ question.answer }}
            </p>
          </app-drtv-faq>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="drtv-cta">
  <div class="bg-image-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="drtv-cta__grid">
          <div class="drtv-cta__content">
            <img
              loading="lazy"
              src="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/cmnh-balloon.png"
              alt="CMN Hospitals Balloon Logo"
              width="45"
              height="71"
            />
            <p>
              Children depend on member hospitals for world-class care, and
              these hospitals depend on you.
            </p>
            <a
              href="#"
              class="drtv-cta__give"
              (click)="scrollTo($event, 'donateNow')"
              ><h2>
                JOIN OUR MIRACLE<br />
                NETWORK TODAY<br />
                <span class="drtv-cta__callout">DONATE NOW.</span>
              </h2></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
