import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AboutComponent } from "./about/about.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CampaignGreetingComponent } from "./campaign/campaign-greeting.component";
import { AutofocusDirective } from "./common/autofocus.directive";
import { TranslatePipe } from "./common/translate.pipe";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ContactComponent } from "./contact/contact.component";
import { DonateComponent } from "./donate/donate.component";
import { GiftAmountComponent } from "./donate/gift-amount.component";
import { DonorComponent } from "./donor/donor.component";
import { DrtvDonateComponent } from "./drtv-donate/drtv-donate.component";
import { DrtvFaqComponent } from "./drtv-faq/drtv-faq.component";
import { DrtvFooterComponent } from "./drtv-footer/drtv-footer.component";
import { DrtvGridComponent } from './drtv-grid/drtv-grid.component';
import { DrtvLowerSectionComponent } from './drtv-lower-section/drtv-lower-section.component';
import { DrtvMenuComponent } from "./drtv-menu/drtv-menu.component";
import { DrtvStatIconComponent } from "./drtv-stat-icon/drtv-stat-icon.component";
import { DrtvStatsComponent } from './drtv-stats/drtv-stats.component';
import { DrtvThankyouMessageComponent } from "./drtv-thankyou-message/drtv-thankyou.component";
import { FooterBarComponent } from "./footer-bar/footer-bar.component";
import { GetDonationsComponent } from "./get-donations/get-donations.component";
import { HeaderBarComponent } from "./header-bar/header-bar.component";
import { HomeComponent } from "./home/home.component";
import { HonorComponent } from "./honor/honor.component";
import { HospitalSearchComponent } from './hospital-search/hospital-search.component';
import { LanguageSelectComponent } from "./language-select/language-select.component";
import { ModalComponent } from "./modal/modal.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { UnderConstructionComponent } from "./under-construction/under-construction.component";
import { VerifyAddressComponent } from "./verify-address/verify-address.component";
import { AdditionalInformationComponent } from "./donate/additional-information.component";

@NgModule({
  declarations: [
    AppComponent,
    DonateComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    GetDonationsComponent,
    GiftAmountComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    TranslatePipe,
    PaymentDetailsComponent,
    HonorComponent,
    VerifyAddressComponent,
    HeaderBarComponent,
    FooterBarComponent,
    SpinnerComponent,
    LanguageSelectComponent,
    ModalComponent,
    DonorComponent,
    CampaignGreetingComponent,
    UnderConstructionComponent,
    ConfirmationDialogComponent,
    AutofocusDirective,
    DrtvDonateComponent,
    DrtvFooterComponent,
    DrtvMenuComponent,
    DrtvStatIconComponent,
    DrtvFaqComponent,
    HospitalSearchComponent,
    DrtvStatsComponent,
    DrtvGridComponent,
    DrtvLowerSectionComponent,
    DrtvThankyouMessageComponent,
    AdditionalInformationComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: "toast-bottom-right",
      enableHtml: true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
