import { Component, OnDestroy, } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-base',
  template: '',
  styleUrls: []
})
export abstract class BaseComponent implements OnDestroy {

    private _subscriptions: Subscription[] = [];
    protected abstract _afterComponentInit(): void;
    protected abstract _afterComponentDestroy(): void;

    ngOnInit(): void {
      this._afterComponentInit();
    }

    ngOnDestroy(): void {
      this._subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
      this._afterComponentDestroy();
    }
    
    addSubscription(sub: Subscription) {
      this._subscriptions.push(sub);
    }
}