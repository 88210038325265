<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="footer" style="padding: 15px 0">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <app-language-select></app-language-select>
            </div>
          </div>
          <div class="col-xs-12 col-sm-8">
            <span style="font-size: 15px; color: #fff">
              &copy; {{ thisYear }} Children's Miracle Network - v:{{ version }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
