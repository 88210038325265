import { Component, ElementRef, OnInit } from "@angular/core";

import { Spinner } from "spin.js";
import { EventBusService, EventsEnum } from "../common/event-bus.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  isVisible = false;

  message: string = "Please wait ...";
  spinnerOptions: ISpinnerOptions = {
    radius: 40,
    lines: 7,
    length: 0,
    width: 30,
    speed: 1.7,
    corners: 1.0,
    trail: 100,
    color: "#F58A00",
  };
  spinner: any;
  element: any;

  constructor(
    private hostElement: ElementRef,
    private events: EventBusService
  ) {}

  ngOnInit(): void {
    this.element = this.hostElement.nativeElement;
    this.spinner = new Spinner(this.spinnerOptions);

    this.events.on(EventsEnum.SpinnerToggle).subscribe((event) => {
      this.toggleSpinner(event.data);
    });
  }

  toggleSpinner = (show) => {
    this.isVisible = show;

    if (show) {
      this.spinner.spin(this.element.firstChild);
    } else {
      this.spinner.stop();
    }
  };
}

interface ISpinnerOptions {
  lines?: number; // The number of lines to draw
  length?: number; // The length of each line
  width?: number; // The line thickness
  radius?: number; // The radius of the inner circle
  corners?: number; // Corner roundness (0..1)
  rotate?: number; // The rotation offset
  direction?: number; // 1: clockwise, -1: counterclockwise
  color?: any; // #rgb or #rrggbb or array of colors
  speed?: number; // Rounds per second
  trail?: number; // Afterglow percentage
  shadow?: boolean; // Whether to render a shadow
  hwaccel?: boolean; // Whether to use hardware acceleration
  className?: string; // The CSS class to assign to the spinner
  zIndex?: number; // The z-index (defaults to 2000000000)
  top?: string; // Top position relative to parent in px
  left?: string; // Left position relative to parent in px
}
