import { Component, Input } from "@angular/core";
import { BaseComponent } from "../base.component";
import { DonorService } from "../donor.service";

@Component({
  selector: "additional-info",
  templateUrl: "../donate/additional-information.component.html",
  styleUrl: "../donate/additional-information.component.scss"
})
export class AdditionalInformationComponent extends BaseComponent {
    @Input() donorEditCommon: DonorService;

    protected _afterComponentInit(): void {}
    protected _afterComponentDestroy(): void {}

    /**
     * Although this doesn't have much functionality yet, I'm leaving it as a separate component
     * in case we decide to implement more logic on how to gather additional information.
     */
    constructor() {
        super();
    
    }

}