import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  @Input() shown = false;
  @Input() title = "";
  @Input() subTitle = "";
  @Input() onCloseText = "";
  @Input() onContinueText = "";
  @Input() onAcceptText = "";
  @Input() onSuccessText = "";
  @Input() size = "lg"

  @Output() onClose = new EventEmitter();
  @Output() onContinue = new EventEmitter();
  @Output() onAccept = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  _containerClasses = "modal";

  constructor() {}

  ngOnInit(): void {}

  handleClose = () => {
    this.onClose.emit();
  };

  handleContinue = () => {
    this.onContinue.emit();
  };

  handleAccept = () => {
    this.onAccept.emit();
  };

  handleSuccess = () => {
    this.onSuccess.emit();
  };
}
