import { Injectable } from "@angular/core";

declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  // @ts-ignore
  standardEventEmitter(eventName: Gtag.EventNames, eventOptions: Gtag.EventParams) {
    if (typeof gtag !== "function") {
      return;
    }

    gtag("event", eventName, {
      ...eventOptions,
    });
  }

  customEventEmitter({
    eventName,
    eventCategory,
    eventAction,
    eventValue,
    eventLabel,
  }: {
    eventName: string;
    eventCategory: string;
    eventAction: string;
    eventValue?: number;
    eventLabel?: string;
  }) {
    if (typeof gtag !== "function") {
      return;
    }

    gtag("event", eventName, {
      eventCategory,
      eventAction,
      eventValue,
      eventLabel,
    });
  }

  customEventParamsEmitter(eventName: string, eventParams: object) {
    if (typeof gtag !== "function") {
      return;
    }

    gtag("event", eventName, eventParams);
  }
}
