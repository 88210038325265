import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { format } from "date-fns";

import { ILoggerOptions } from "../domain/types";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  constructor(private toastr: ToastrService) {}

  log(args: ILoggerOptions) {
    this.logIt({ ...args, showToast: true, toastType: "info" });
  }

  logWarning(args: ILoggerOptions) {
    this.logIt({ ...args, showToast: true, toastType: "warning" });
  }

  logSuccess(args: ILoggerOptions) {
    this.logIt({ ...args, showToast: true, toastType: "success" });
  }

  logError(args: ILoggerOptions) {
    if(environment.public){
      this.logIt({ message:'Something went wrong', showToast: true, toastType: "error" });
    } else{
      this.logIt({ ...args, showToast: true, toastType: "error" });
    }
  }

  logIt({ toastType, source, data, showToast, message }: ILoggerOptions) {
    console.log("logger error", arguments);
    const log = toastType === "error" ? console.error : console.log;

    source = source ? "[" + source + "] " : "";

    if (data) {
      log(source, format(new Date(), "h:mm:ss.sss ") + message, data);
    } else {
      log(source, format(new Date(), "h:mm:ss.sss ") + message, "");
    }

    if (showToast) {
      if (toastType === "error") {
        this.toastr.error(message);
      } else if (toastType === "warning") {
        this.toastr.warning(message);
      } else if (toastType === "success") {
        this.toastr.success(message);
      } else {
        this.toastr.info(message);
      }
    }
  }
}
