import { NgModule } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AboutComponent } from "./about/about.component";
import { AppComponent } from "./app.component";
import { ContactComponent } from "./contact/contact.component";
import { DonateComponent } from "./donate/donate.component";
import { DrtvDonateComponent } from "./drtv-donate/drtv-donate.component";
import { GetDonationsComponent } from "./get-donations/get-donations.component";
import { HomeComponent } from "./home/home.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { NotFoundComponent } from "./not-found/not-found.component";

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "donate",
    component: DonateComponent,
    data: {
      title: "Donate - My Gift for Kids",
      settings: {
        nav: 1,
        topNav: false,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Donate",
      },
    },
  },
  {
    path: "helpkidstoday/:cid/:hid",
    component: DrtvDonateComponent,
    data: {
      title: "Help Kids Today",
      settings: {
        nav: 1,
        topNav: false,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Donate",
      },
    },
  },
  {
    path: "donate/helpkidstoday/:cid/:hid",
    component: DrtvDonateComponent,
    data: {
      title: "Help Kids Today - Donate",
      settings: {
        nav: 1,
        topNav: false,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Donate",
      },
    },
  },
  {
    path: "donate/helpkidstoday",
    component: DrtvDonateComponent,
    data: {
      title: "Help Kids Today - Donate",
      settings: {
        nav: 1,
        topNav: false,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Donate",
      },
    },
  },
  {
    path: "splash/:cid/:hid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "splash/:cid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
        errorPage: false,
        dialogPage: false,
      },
    },
  },

  {
    path: "splash/:cid/:hid/:rid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "donor/:yes/:cid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "donor/:yes/:cid/:hid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "donor/:yes/:cid/:hid/:rid",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "amountspecific/:yes/:cid/:hid/:rid/:amount/:recurring",
    component: HomeComponent,
    data: {
      title: "My Gift for Kids",
      settings: {
        nav: 1,
        topNav: true,
        sideNav: true,
        loginRequired: false,
        roles: [],
        icon: "fa-home",
        content: "Home",
      },
    },
  },
  {
    path: "about",
    component: AboutComponent,
    data: {
      title: "About - My Gift for Kids",
      settings: {
        nav: 2,
        topNav: true,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-building",
        content: "About",
      },
    },
  },
  {
    path: "contact",
    component: ContactComponent,
    data: {
      title: "Contact Us - My Gift for Kids",
      settings: {
        nav: 2,
        topNav: true,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-telephone",
        content: "About",
      },
    },
  },
  {
    path: "getdonations",
    component: GetDonationsComponent,
    data: {
      title: "Get Donation - My Gift for Kids",
      settings: {
        nav: 2,
        topNav: true,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-building",
        content: "About",
      },
    },
  },
  {
    path: "getdonor",
    component: DonateComponent,
    data: {
      title: "Get Donor - My Gift for Kids",
      settings: {
        nav: 2,
        topNav: true,
        sideNav: false,
        loginRequired: false,
        roles: [],
        icon: "fa-building",
        content: "About",
      },
    },
  },
  {
    path: "notauthorized",
    component: NotAuthorizedComponent,
    data: {
      title: "not authorized",
      settings: {
        errorPage: true,
      },
    },
  },
  {
    path: "404",
    component: NotFoundComponent,
    data: {
      template: "<app-not-found></app-not-found>",
      title: "404 - My Gift for Kids",
      settings: {
        errorPage: true,
      },
    },
  },
  {
    path: "**",
    redirectTo: "404",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
