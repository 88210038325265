<app-drtv-menu></app-drtv-menu>

<section class="drtv-header">
  <div class="drtv-banner"></div>

  <div class="drtv-greeting">
    <img
      src="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/MarieDRTVPortrait.jpg"
      class="img-responsive"
      alt="Marie Osmond"
    />

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-8 col-md-offset-4">
          <h1 class="drtv-tagline">“Make <b>every moment</b> count.”</h1>
          <h2>
            <span class="name"
              ><span class="visible-xs">- </span>Marie Osmond</span
            ><span class="hide-xs"> - </span><br class="visible-xs" />Co-Founder,
            Children’s Miracle Network Hospitals
          </h2>
        </div>
      </div>
    </div>

    <div class="give-btn-container">
      <button class="yellow-btn give-btn" title="Give Now" (click)="scrollToDonate($event)">Give Now</button>
    </div>
  </div>
</section>

<section class="hospital-search-section" id="hospitalSearch" #hospitalSearch>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        <h2 class="hospital-search-title text-center">
          Your donation will go directly to
          <b>your nearest member hospital of Children's Miracle Network hospitals.</b>
        </h2>
      </div>

      <div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <p>
          Input your billing zip code to find the Children’s Miracle Network
          Hospital member hospital closest to you:
        </p>
      </div>

      <div class="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        <app-hospital-search [showTitle]="false" [largeSearch]="true"></app-hospital-search>
      </div>
    </div>
  </div>
</section>

<section class="drtv-donate-section" id="donateNow" #donateNow>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-lg-10 col-lg-offset-1">
        <div class="row">
          <div class="col-xs-12">
            <h2 class="donate-title text-center">
              <b>Make a difference</b><br />in a child’s life near you
            </h2>
          </div>
        </div>
        <div class="row">
          <app-donate [drtv]="true" #donateComponent class="drtv-donation"></app-donate>
        </div>

        <drtv-thankyou (onPageRefresh)="onDrtvThankyouPageRefresh($event)" [hidden]="!donationProcessed"></drtv-thankyou>
        
        <div class="row">
          <div class="col-xs-12">
            <a
              href="https://www.guidestar.org/profile/87-0387205"
              target="_blank"
              class="guidestar"
              style="outline: currentcolor none medium"
              ><img
                src="https://widgets.guidestar.org/gximage2?o=8291642&amp;l=v4"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-drtv-stats></app-drtv-stats>

<app-drtv-grid></app-drtv-grid>

<app-drtv-lower-section></app-drtv-lower-section>

<app-drtv-footer></app-drtv-footer>
