import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { RepositoryLookupService } from "./repository-lookup.service";
import { RepositoryDonorService } from "./repository-donor.service";
import { CommonService } from "../common/common.service";
import { ConfigService } from "../config.service";
import {
  ICampaignBindingModel,
  IHospitalBindingModel,
  IHospitalCMNHModel,
} from "../domain/types";
import { Observable } from "rxjs";
import { RepositoryCampaignService } from "../data/repository-campaign.service";
import { RepositoryBraintreeService } from "./repository-braintree.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public Lookup: RepositoryLookupService;
  public Donor: RepositoryDonorService;
  public Campaign: RepositoryCampaignService;
  public Braintree: RepositoryBraintreeService;

  constructor(
    private _commonService: CommonService,
    private _configService: ConfigService,
    private _http: HttpClient,
    repositoryLookup: RepositoryLookupService,
    repositoryDonor: RepositoryDonorService,
    repositoryCampagin: RepositoryCampaignService,
    repositoryBraintree: RepositoryBraintreeService
  ) {
    this.Lookup = repositoryLookup;
    this.Donor = repositoryDonor;
    this.Campaign = repositoryCampagin;
    this.Braintree = repositoryBraintree;
  }

  getHospital(hospitalId): Observable<IHospitalBindingModel> {
    const endpoint =
      this._commonService.serviceUrl(this._configService.apiServices.hospital) +
      "Get/" +
      hospitalId;

    return this._http.get<IHospitalBindingModel>(endpoint);
  }

  public getCampaignForCsr(campaignId): Observable<ICampaignBindingModel> {
    const endpoint =
      this._commonService.serviceUrl(this._configService.apiServices.campaign) +
      "GetForCsr/" +
      campaignId;

    return this._http.get<ICampaignBindingModel>(endpoint);
  }

  public getRadioStation(radioStationNameId) {
    const endpoint =
      this._commonService.serviceUrl(
        this._configService.apiServices.radioStationName
      ) +
      "Get/" +
      radioStationNameId;

    return this._http.get(endpoint);
  }

  public getHospitalByPostal(postalCode: string) {
    const endpoint =
      this._commonService.serviceUrl(
        this._configService.apiServices.localHospital
      ) + postalCode;

    return this._http.get<IHospitalCMNHModel[]>(endpoint);
  }
}
