<section class="drtv-stats">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>
          How Your <br />
          <span class="drtv-stats__bigger-title">Donation Helps</span>
        </h2>
        <div class="drtv_stats__icons">
          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-heart.png" percentage="14">
            helps provide <span class="red-highlight">charitable care to patients</span>
          </app-drtv-stat-icon>

          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-ekg.png" percentage="24">
            goes to improving life-saving <span class="red-highlight">equipment</span>
          </app-drtv-stat-icon>

          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-book.png" percentage="8">
            provides <span class="red-highlight">education</span> for patients, families and the community
          </app-drtv-stat-icon>

          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-iv-drip.png" percentage="23">
            provides <span class="red-highlight">advancement services</span> that support innovative programs and projects
          </app-drtv-stat-icon>

          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-microscope.png" percentage="7">
            supports <span class="red-highlight">research and treatment</span> for how we care for children
          </app-drtv-stat-icon>

          <app-drtv-stat-icon icon="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/drtv/icons/icon-hand-heart.png" percentage="24">
            contributes to <span class="red-highlight">patient services</span> for patients' and families' overall well-being
          </app-drtv-stat-icon>
        </div>
      </div>
    </div>
  </div>
</section>
