<nav
  class="navbar navbar-inverse navbar-tophead navbar-fixed-top"
  role="navigation"
  [hidden]="isHidden"
>
  <div class="container">
    <div class="navbar-header">
      <button
        type="button"
        class="btn btn-success navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#app-navbar"
        aria-expanded="false"
      >
        <span class="glyphicon glyphicon-chevron-down"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="app-navbar">
      <ul class="nav navbar-nav navbar-nav-tophead">
        <li (click)="homeClicked()">
          <a
            [title]="'Back to home page'"
            style="padding-top: 20px !important; font-size: 15px; color: #fff"
          >
            <i class="fa fa-home"></i>
            Home
          </a>
        </li>

        <li (click)="aboutClicked()">
          <a
            style="padding-top: 20px !important; font-size: 15px; color: #fff"
          >
            <i class="fa fa-bookmark"></i>
            About
          </a>
        </li>

        <li (click)="contactClicked()">
          <a
            style="padding-top: 20px !important; font-size: 15px; color: #fff"
          >
            <i class="fa fa-phone"></i>
            Contact Us
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
