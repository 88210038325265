import { Component } from "@angular/core";

import { CommonService } from "../common/common.service";

@Component({
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  private _controllerId: string = "notFound";
  public title: string = "notFound";

  constructor(private _commonService: CommonService) {
    this._commonService
      .activateController([], this._controllerId)
      .then((results) => results)
      .catch((error) => console.error(error));
  }
}
