import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { LanguageService } from "../common/language.service";
import { ChargeFrequencyEnum } from "../domain/enums";

import { DonorTransactionBindingModel } from "../domain/models";
import { DonationAmount } from "../domain/types";

@Component({
  selector: "app-gift-amount",
  templateUrl: "./gift-amount.component.html",
  styleUrls: ["./gift-amount.component.scss"],
})
export class GiftAmountComponent implements OnChanges, OnInit {
  @Input() language = "en-US";
  @Input() gettingCampaign = false;
  @Input() donationAmounts: DonationAmount[] = [];
  @Input() transaction = new DonorTransactionBindingModel();
  @Input() donation: DonationAmount = "Other";
  @Input() form: any;
  @Input() defaultDonationAmount: number;
  @Input() drtv = false;
  @Output() onDonationAmountChange = new EventEmitter<number>();
  amountValue: boolean = false;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.amountValue = true;
    this.languageService.getTranslations(this.language);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.language?.currentValue !== changes.language?.previousValue) {
      this.languageService.getTranslations(changes.language.currentValue);
    }
  }

  setDonation(amount: DonationAmount) {
    this.amountValue = false;
    this.donation = amount;
    this.transaction.donationAmount = amount === "Other" ? null : amount;
    this.onDonationAmountChange.emit();
  }

  toggleChargeFrequency() {
    this.transaction.chargeFrequency =
      this.transaction.chargeFrequency === ChargeFrequencyEnum.Monthly
        ? ChargeFrequencyEnum.OneTime
        : ChargeFrequencyEnum.Monthly;
  }

  toggleAnonymousGift() {
    this.transaction.displayInDash = !this.transaction.displayInDash;
  }

  clampDonationAmount(event: Event): void {
    const input = event.target as HTMLInputElement;
    const { value, selectionStart } = input;
    const originalValue = value;
    const originalCursorPosition = selectionStart;
    const maxValue = 10000000;

    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      // Remove the last char if there's an adicional decimal point
      input.value = value.slice(0, -1);
    }

    // Remove all characters except digits and decimal point
    let newValue = originalValue.replace(/[^0-9.]/g, "");

    // Prevent multiple decimal points
    newValue = newValue.split(".").slice(0, 2).join(".");

    // Check if the value exceeds the maximum limit
    const numericValue = parseFloat(newValue);
    if (numericValue > maxValue) {
      newValue = "10000000";
    } else if (numericValue < 0.01) {
      newValue = "0.01";
    }

    // Update the value only if it has changed
    if (newValue !== originalValue) {
      // Adjust the cursor position
      const cursorPositionAdjustment = originalValue
        .substring(0, originalCursorPosition)
        .replace(/[^0-9.]/g, "").length;
      if (newValue === "0.01") {
        setTimeout(() => {
          input.value = newValue;
          this.transaction.donationAmount = parseFloat(newValue);
          input.setSelectionRange(
            cursorPositionAdjustment,
            cursorPositionAdjustment
          );
        }, 1000);
      } else {
        input.value = newValue;
        this.transaction.donationAmount = parseFloat(newValue);
        setTimeout(() => {
          input.setSelectionRange(
            cursorPositionAdjustment,
            cursorPositionAdjustment
          );
        }, 0);
      }
    }
  }
}
