<details
  [attr.aria-expanded]="expanded"
  (toggle)="toggleAnswer($event)"
  [open]="defaultOpen"
>
  <summary><ng-content></ng-content></summary>
  <div class="answer">
    <ng-content select="[answer]"></ng-content>
  </div>
</details>
