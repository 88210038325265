import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseComponent } from "../base.component";
import { CommonService } from "../common/common.service";
import { DonateComponent } from "../donate/donate.component";

@Component({
  selector: "app-drtv-donate",
  templateUrl: "./drtv-donate.component.html",
  styleUrls: ["./drtv-donate.component.scss"],
})
export class DrtvDonateComponent extends BaseComponent {

  @ViewChild("donateNow") donateNow: ElementRef;
  @ViewChild("hospitalSearch") hospitalSearch: ElementRef;
  donationProcessed: boolean = false;
  @ViewChild("donateComponent") donateComponent: DonateComponent;
  
  constructor(private commonService: CommonService) {
    super();
  }


  protected _afterComponentInit(): void {
   this.addSubscription(
    this.commonService.donationProcessed$.subscribe(value => {
      this.donationProcessed = value;
    })
   )
  }

  protected _afterComponentDestroy(): void {}

  scrollToDonate(e: Event) {
    e.preventDefault();
    this.donateNow.nativeElement?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    if(this.donationProcessed) {
      this.commonService.showConfirmCreditCardECheckPaymentModal(true);
    }
  }

  scrollToHospitalSearch(e: Event) {
    e.preventDefault();
    this.hospitalSearch.nativeElement?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  
  onDrtvThankyouPageRefresh(value: boolean) {
    this.donateComponent.continueWithCall(value);
  }
}
