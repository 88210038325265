import { Component, Input, OnInit } from "@angular/core";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-drtv-faq",
  templateUrl: "./drtv-faq.component.html",
  styleUrls: ["./drtv-faq.component.scss"],
})
export class DrtvFaqComponent implements OnInit {
  @Input() defaultOpen = false;

  expanded = false;

  constructor(private _googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit(): void {}

  toggleAnswer({ target }: { target: HTMLDetailsElement }) {
    const eventLabel = target
      .querySelector("summary")
      .textContent.trim() as string;

    if (target.open) {
      this.expanded = true;

      // Don't send a GA Event if the Question defaults to open
      if (!this.defaultOpen) {
        this.faqEvent("open", eventLabel);
      }
    } else {
      this.expanded = false;

      // Set open to false if the default open FAQ has been closed
      this.defaultOpen = false;
      this.faqEvent("close", eventLabel);
    }
  }

  faqEvent(eventAction: string, eventLabel: string) {
    this._googleAnalytics.customEventEmitter({
      eventName: "toggle_faq",
      eventCategory: "toggle_faq",
      eventAction,
      eventLabel,
    });
  }
}
