import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DonorService } from "../donor.service";
import { ControlContainer, UntypedFormControl, FormGroup, NgForm } from "@angular/forms";

@Component({
  selector: "app-donor",
  templateUrl: "./donor.component.html",
  styleUrls: ["./donor.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DonorComponent implements OnInit {
  @Input() donorEditCommon: DonorService;
  @Input() form: NgForm;
  @Output() onNameChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  showError(ctl: UntypedFormControl){
    return this.form && ctl && (this.form.submitted || ctl.touched || ctl.dirty);
  }

}
