import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ControlContainer, UntypedFormControl, NgForm } from "@angular/forms";

import * as Enums from "../domain/enums";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PaymentDetailsComponent implements OnChanges {
  @Input() transaction: any;
  @Input() form: any;
  @ViewChild("nameoncard") nameoncard: UntypedFormControl;
  @ViewChild("bankname") bankname: UntypedFormControl;
  @ViewChild("routingnumber") routingnumber: UntypedFormControl;
  @ViewChild("banknumber") banknumber: UntypedFormControl;

  public paymentType = Enums.TransactionTypeEnum.CreditCard;

  public accountNameChanged = false;

  constructor(private _dataService: DataService) {}

  get hasErrors(){
    if(this.bankname && this.bankname.invalid){
      return true;
    }
    if(this.routingnumber && this.routingnumber.invalid){
      return true;
    }
    if(this.banknumber && this.banknumber.invalid){
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
  }

  creditCardClicked() {
    this.paymentType = Enums.TransactionTypeEnum.CreditCard;
  }

  eCheckClicked() {
    this.paymentType = Enums.TransactionTypeEnum.ECheck;
  }

  getBankName() {
    if (this.transaction.abaNumber === undefined) {
      return;
    }
    var self = this;
    this._dataService.Donor.getBankFromRouting(
      self.transaction.abaNumber
    ).subscribe((result) => {
      var routingBankName = <string>result;
      self.transaction.bankName = routingBankName;
    }, console.error);
  }

  showError(ctl: UntypedFormControl){
    return this.form && ctl && (this.form.submitted || ctl.touched || ctl.dirty);
  }

}
