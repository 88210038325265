<div class="amount-row" style="margin-bottom: 20px">
  <div class="col-sm-12">
    <div
      class="well"
      style="border: solid 1px #ccc; box-shadow: 0 0 5px 2px #ddd"
    >
      <div class="form-group">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-8 col-sm-12 text-left gift-amount">
          <label>
            <strong
              style="
                display: block;
                padding-bottom: 5px;
                border-bottom: solid 1px #ddd;
                margin-bottom: 15px;
              "
            >
              <strong>{{ "AMOUNT" | translate }}</strong>
            </strong>
          </label>

          <div class="text-center" [hidden]="gettingCampaign">
            <button
              type="button"
              class="btn btn-keep-text-color"
              style="margin: 3px"
              *ngFor="let amount of donationAmounts"
              [ngClass]="{ 'btn-danger': amount === donation || (!donation && amount === defaultDonationAmount) }"
              (click)="setDonation(amount)"
            >
              <strong style="font-size: 18px"> ${{ amount }} </strong>
            </button>
            <button
              type="button"
              class="btn btn-keep-text-color"
              style="margin: 3px"
              [ngClass]="{ 'btn-danger': donation === 'Other' }"
              (click)="setDonation('Other')"
            >
              <strong style="font-size: 18px">
                {{ "OTHER" | translate }}
              </strong>
            </button>
          </div>
          <div
            class="text-center"
            [hidden]="!gettingCampaign"
            style="padding: 15px 0"
          >
            <i class="text-warning fa fa-3x fa-spin fa-spinner"></i>
          </div>
          <input
            *ngIf="donation === 'Other'"
            type="text"
            class="form-control form-control-required"
            id="donationAmount"
            name="donationAmount"
            [(ngModel)]="transaction.donationAmount"
            (input)="clampDonationAmount($event);onDonationAmountChange.emit()"
            appAutofocus
            pattern="^(?=.*[1-9])\d*(?:\.\d{1,2})?$"
            style="margin-top: 10px; font-size: 20px"
            placeholder="0.00"
            required
            min="0"
            inputmode="decimal"
            digitonly
          />

          <div style="margin-top: 10px">
            <div
              class="well well-sm"
              (click)="toggleChargeFrequency()"
              style="
                color: #333;
                background: #ddd;
                position: relative;
                padding-left: 45px;
                cursor: pointer;
                margin-bottom: 10px;
              "
            >
              <button
                type="button"
                class="btn btn-sm donation-checkbox"
                style="
                  color: #333;
                  font-size: 14px;
                  position: absolute;
                  top: 4px;
                  left: 9px;
                "
              >
                <i
                  class="fa fa-1-5x"
                  [ngClass]="{
                    'fa-check-square-o': transaction.chargeFrequency === 2,
                    'fa-square-o': transaction.chargeFrequency === 1
                  }"
                ></i>
              </button>
              <strong
                style="text-transform: unset !important; font-size: 18px"
                class="text-danger"
              >
                {{ "SPLASH_MONTHLY_GIFT_1" | translate }}
              </strong>
              <span style="display: block; font-size: 12px">
                {{ "SPLASH_MONTHLY_GIFT_2" | translate }}
              </span>
            </div>
          </div>

          <div style="margin-top: 5px" [hidden]="drtv">
            <div
              class="well well-sm"
              (click)="toggleAnonymousGift()"
              style="
                color: #333;
                background: #ddd;
                position: relative;
                padding-left: 45px;
                cursor: pointer;
                margin-bottom: 10px;
              "
            >
              <button
                type="button"
                class="btn btn-sm donation-checkbox"
                style="
                  color: #333;
                  font-size: 14px;
                  position: absolute;
                  top: 4px;
                  left: 9px;
                "
              >
                <i
                  class="fa fa-1-5x"
                  [ngClass]="{
                    'fa-check-square-o': !transaction.displayInDash,
                    'fa-square-o': transaction.displayInDash
                  }"
                ></i>
              </button>
              <strong style="text-transform: unset !important">
                {{ "SPLASH_ANONYMOUSLY" | translate }}
              </strong>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12"></div>
      </div>
      <div style="clear: both">&nbsp;&nbsp;&nbsp;</div>
    </div>
  </div>
</div>
