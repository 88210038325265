import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { CommonService } from "./common/common.service";
import { EventBusService, EventsEnum } from "./common/event-bus.service";
import { DonorBindingModel } from "./domain/models";
import {
  IDonorBindingModel, IGiftNotificationBindingModel,
  ILookupModelInt,
  ILookupModelString,
  ILookupSalutationModel,
  ILookupSendAppealType,
  ILookupSuffixModel,
  IAddressDataBinding
} from "./domain/types";
import { DataService } from "./services/data.service";


export class EditDonorReturnValue {
  reason: string;
  firstName: string;
  lastName: string;
  companyName: string;
  donorId: number;
  hospitalId: number;
  donorSeed: string;
  constructor() {
    this.hospitalId = null;
  }
}

@Injectable({
  providedIn: "root",
})
export class DonorService {
  private _commonData = {
    donor: new DonorBindingModel(), //{} as IDonorBindingModel,
    inCashiering: false,
    inCsr: false,
  };
  private _prevSalutations: Array<ILookupSalutationModel>;
  private _prevSuffixes: Array<ILookupSuffixModel>;
  private _prevSalutation: string;
  private _prevSuffix: string;
  private _inHonorRequested: boolean;

  public melissaData = {} as IAddressDataBinding;
  public donor: IDonorBindingModel;
  public newDonor: boolean = false;
  public languages: Array<ILookupModelString>;
  public cities: Array<IAddressDataBinding>;
  public states: Array<ILookupModelInt>;
  public sendAppealsTypes: Array<ILookupSendAppealType>;
  public salutations: Array<ILookupSalutationModel>;
  public suffixes: Array<ILookupSuffixModel>;
  public lookingUpZip: boolean = false;
  public inCashiering: boolean = false;
  public inCsr: boolean = false;

  public dialogSubmit;

  constructor(
    private _commonService: CommonService,
    private _dataService: DataService,
    private _eventBus: EventBusService
  ) {
    this.donor = JSON.parse(JSON.stringify(this._commonData.donor)); //Make a copy so changes we do don't affect the other one in case we want to close it without saving.
    this.inCashiering = this._commonData.inCashiering;
    this.inCsr = this._commonData.inCsr;
    if (this.donor.donorId === 0) {
      this.newDonor = true;
    }
    var promises = [];
    promises.push(
      this._getStates(),
      this._getSendAppealsTypes(),
      this._getLanguages(),
      this._getSalutations(),
      this._getSuffixes(),
      this._checkDonorCampaign()
    );
  }

  public verifyAddress(): any {
    this._updateStateAbrev();
    return this._dataService.Donor.verifyAddress(this.donor);
  }

  public languageChanged() {
    if (this.salutations !== undefined) {
      this._prevSalutations = this.salutations.slice();
    }
    if (this.suffixes !== undefined) {
      this._prevSuffixes = this.suffixes.slice();
    }
    this._prevSalutation = this.donor.salutation;
    this._prevSuffix = this.donor.suffix;
    this._getSalutations();
    this._getSuffixes();
  }

  inHonorRequested(val: boolean) {
    this._inHonorRequested = val;
  }

  lookUpZipBlur(postalCode, notifySomeone: IGiftNotificationBindingModel = null) {
    if (!postalCode) return;
    this._getCities(postalCode, notifySomeone);
  }

  public getLocalizedHospital(postalCode: string) {
    if (!postalCode) return;
    this._dataService.getHospitalByPostal(postalCode).subscribe(hospitals => {
      const hospital = hospitals[0];

      this.donor.cmnhHospitalId = hospital.cmnHospitalId;
      this.donor.cmnhHospitalName = hospital.displayName ?? hospital.hospitalName;
    });
  }

  private _checkDonorCampaign() {
    if (this.donor.campaignId === undefined || this.donor.campaignId === 0) {
      if (
        this._commonService.globalData.whiteMailCampaignId === undefined ||
        this._commonService.globalData.whiteMailCampaignId === 0
      ) {
        this._dataService.Campaign.getWhiteMail().subscribe(
          (result) => {
            var camp: any = result;
            this._commonService.globalData.whiteMailCampaignId =
              camp.campaignId;
            //this.donor.campaignId = this._commonService.globalData.whiteMailCampaignId;
          },
          (error) => {
            this._commonService.logger.logError({
              message: `Could not get white mail campaign<br />${this._commonService.jsonMessage(
                error
              )}`,
            });
          }
        );
      } else {
        this.donor.campaignId = this._commonService.globalData.whiteMailCampaignId;
      }
    }
  }

  private _getCities(postalCode: string, notifySomeone: IGiftNotificationBindingModel = null) {
    this.lookingUpZip = true;
    this._dataService.Donor.lookupZip(postalCode).subscribe(
      (result) => { 
        if (!result) {
          this._commonService.toast.warning(
            "The Zip code entered was not found"
          );
          return;
        }

        if(notifySomeone){
          notifySomeone.city = result.city;
          notifySomeone.stateId = result.stateId;
        } else {
          this.donor.city = result.city;
          this.donor.stateId = result.stateId;
          this.donor.stateAbrev = result.stateAbrev;
        }
        this.lookingUpZip = false;
      },
      (error) => {
        this._commonService.logger.logError({
          message: `Could not get Zip's Cities<br />${this._commonService.jsonMessage(
            error
          )}`,
        });
        this.lookingUpZip = false;
      }
    );
  }

  public saveDonor(token: string): any {
    this._eventBus.emit({ name: EventsEnum.SpinnerToggle, data: true });
    return this._dataService.Donor.save(this.donor, token).pipe(
      switchMap((result) => {
        this._commonService.logger.logSuccess({
          message: "Donor's data was saved",
        });
        //this.donor = new DonorBindingModel();
        this._eventBus.emit({ name: EventsEnum.DonorUpdated });
        this._eventBus.emit({
          name: EventsEnum.SpinnerToggle,
          data: false,
        });
        return of(result);
      }),
      catchError((error) => {
        this._commonService.logger.logError({
          message: `Could not save the Donor<br />${this._commonService.jsonMessage(
            error
          )}`,
        });
        this._eventBus.emit({ name: EventsEnum.SpinnerToggle, data: false });
        return error;
      })
    );

    // .subscribe(
    //   (result) => {
    //     this._commonService.logger.logSuccess({
    //       message: "Donor's data was saved",
    //     });
    //     this.donor = new DonorBindingModel();
    //     this._eventBus.emit({ name: EventsEnum.DonorUpdated });
    //     this._eventBus.emit({ name: EventsEnum.SpinnerToggle, data: false });
    //     return this.donor;
    //   },
    //   (error) => {
    //     this._commonService.logger.logError({
    //       message: `Could not save the Donor<br />${this._commonService.jsonMessage(
    //         error
    //       )}`,
    //     });
    //     this._eventBus.emit({ name: EventsEnum.SpinnerToggle, data: false });
    //     return this.donor;
    //   }
    // );
  }

  private _getSalutations() {
    this._dataService.Lookup.salutations(this.donor.languageValue).subscribe(
      (result) => {
        this.salutations = result as Array<ILookupSalutationModel>;
        this._changeSalutationOnLanguage();
      },
      (error) => {
        this._commonService.logger.logError({
          message:
            "Could not get the list of Salutations<br />" +
            this._commonService.jsonMessage(error),
        });
      }
    );
  }

  private _getSuffixes() {
    this._dataService.Lookup.suffixes(this.donor.languageValue).subscribe(
      (result) => {
        this.suffixes = result as Array<ILookupSuffixModel>;
        this._changeSuffixOnLanguage();
      },
      (error) => {
        this._commonService.logger.logError({
          message:
            "Could not get the list of Suffixes<br />" +
            this._commonService.jsonMessage(error),
        });
      }
    );
  }

  private _getStates() {
    this._dataService.Lookup.states().subscribe(
      (result) => {
        this.states = result as Array<ILookupModelInt>;
      },
      (error) => {
        this._commonService.logger.logError({
          message:
            "Could not get the list of States<br />" +
            this._commonService.jsonMessage(error),
        });
      }
    );
  }

  private _getSendAppealsTypes() {
    this._dataService.Lookup.sendAppealsTypes(false).subscribe(
      (result) => {
        this.sendAppealsTypes = result as Array<ILookupSendAppealType>;
      },
      (error) => {
        this._commonService.logger.logError({
          message:
            "Could not get the list of Send Appeals Types<br />" +
            this._commonService.jsonMessage(error),
        });
      }
    );
  }

  private _getLanguages() {
    this._dataService.Lookup.languages().subscribe(
      (result) => {
        this.languages = result as Array<ILookupModelString>;
      },
      (error) => {
        this._commonService.logger.logError({
          message:
            "Could not get the list of Languages<br />" +
            this._commonService.jsonMessage(error),
        });
      }
    );
  }

  private _changeSalutationOnLanguage() {
    if (
      this._prevSalutations != undefined &&
      this._prevSalutations.length > 0
    ) {
      var found: boolean = false;
      var id: number = -1;
      for (var i = 0; i < this._prevSalutations.length; i++) {
        var item = <ILookupSalutationModel>this._prevSalutations[i];
        if (item.salutation === this._prevSalutation) {
          id = item.languageLinkId;
          break;
        }
      }
      if (id !== -1) {
        for (var j = 0; j < this.salutations.length; j++) {
          var item2 = <ILookupSalutationModel>this.salutations[j];
          if (item2.languageLinkId === id) {
            this.donor.salutation = item2.salutation;
            found = true;
            break;
          }
        }
      }
      if (!found) {
        this.donor.suffix = null;
      }
    }
  }
  private _changeSuffixOnLanguage() {
    if (this._prevSuffixes != undefined && this._prevSuffixes.length > 0) {
      var found: boolean = false;
      var id: number = -1;
      for (var i = 0; i < this._prevSuffixes.length; i++) {
        var item = <ILookupSuffixModel>this._prevSuffixes[i];
        if (item.suffix === this._prevSuffix) {
          id = item.languageLinkId;
          break;
        }
      }
      if (id !== -1) {
        for (var j = 0; j < this.suffixes.length; j++) {
          var item2 = <ILookupSuffixModel>this.suffixes[j];
          if (item2.languageLinkId === id) {
            this.donor.suffix = item2.suffix;
            found = true;
            break;
          }
        }
      }
      if (!found) {
        this.donor.suffix = null;
      }
    }
  }

  //keep
  private _updateStateAbrev() {
    for (var i = 0; i < this.states.length; i++) {
      var state = this.states[i];
      if (state.value === this.donor.stateId) {
        this.donor.state = state.label;
      }
    }
  }
}
