import { Component, Inject } from "@angular/core";

import { CommonService } from "../common/common.service";
import { EventBusService, EventsEnum } from "../common/event-bus.service";
import { LanguageService } from "../common/language.service";
import { LoggerService } from "../common/logger.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"],
})
export class LanguageSelectComponent {
  public languageValue = "";
  public languages = [];

  constructor(
    private _commonService: CommonService,
    private _languageService: LanguageService,
    private _loggerService: LoggerService,
    private _eventBusService: EventBusService,
    private _googleAnalytics: GoogleAnalyticsService
  ) {
    this._getLanguages();
    this.languageValue = _languageService.language;

    this._eventBusService.on(EventsEnum.LanguageChanged).subscribe((event) => {
      console.log("languageServer.language", this._languageService.language);
      this.languageValue = this._languageService.language;
    });
  }

  private _getLanguages = () => {
    this._languageService
      .getLanguages()
      .then((result) => {
        this.languages = result;
      })
      .catch((error) => {
        const htmlMessage = this._commonService.jsonMessage(error);

        this._loggerService.logError({
          message: `
            Could not get the list of Languages
            <br />
            ${htmlMessage}
          `,
        });
      });
  };

  languageChangedFromDropDown() {
    this._eventBusService.emit({
      name: EventsEnum.LanguageChanged,
      data: this.languageValue,
    });

    this._googleAnalytics.customEventEmitter({
      eventName: "language_switcher",
      eventCategory: "language_switcher",
      eventAction: this.languageValue,
    });
  }
}
