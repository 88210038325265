import { Component, Input, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

import { CommonService } from "../common/common.service";

import { IAddressDataBinding } from "../domain/types";
import { DonorService } from "../donor.service";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-honor",
  templateUrl: "./honor.component.html",
  styleUrls: ["./honor.component.scss"],
})
export class HonorComponent {

  @Input() honorModel: any;
  @Input() giftNotify: any;
  @Input() donorEditCommon: DonorService;
  @Input() form: any;
  @ViewChild("inhonorname") inhonorname: UntypedFormControl;
  @ViewChild("notifyname") notifyname: UntypedFormControl;
  @ViewChild("notifyemail") notifyemail: UntypedFormControl;
  @ViewChild("notifytelephone") notifytelephone: UntypedFormControl;
  @ViewChild("postalCodeNotify") postalCodeNotify: UntypedFormControl;
  @ViewChild("notifyaddress1") notifyaddress1: UntypedFormControl;
  @ViewChild("notifyaddress2") notifyaddress2: UntypedFormControl;
  @ViewChild("notifycity") notifycity: UntypedFormControl;
  @ViewChild("notifystateId") notifystateId: UntypedFormControl;

  accountNameChanged = false;
  lookingUpZip = false;

  notifySomeone = false;
  inMemoryHonorOf = false;
  inMemory = false;
  inHonor = true;

  constructor() {}

  get hasErrors(){
    if(this.inhonorname && this.inhonorname.invalid){
      return true;
    }
    if(this.notifyname && this.notifyname.invalid){
      return true;
    }
    if(this.notifyemail && this.notifyemail.invalid){
      return true;
    }
    if(this.notifytelephone && this.notifytelephone.invalid){
      return true;
    }
    if(this.postalCodeNotify && this.postalCodeNotify.invalid){
      return true;
    }
    if(this.notifyaddress1 && this.notifyaddress1.invalid){
      return true;
    }
    if(this.notifyaddress2 && this.notifyaddress2.invalid){
      return true;
    }
    if(this.notifycity && this.notifycity.invalid){
      return true;
    }
    if(this.notifystateId && this.notifystateId.invalid){
      return true;
    }
    return false;
  }

  showError(ctl: UntypedFormControl){
    return this.form && ctl && (this.form.submitted || ctl.touched || ctl.dirty);
  }
}
