import { Component } from "@angular/core";
import { format } from "date-fns";
import { ConfigService } from "../config.service";

@Component({
  selector: "app-footer-bar",
  templateUrl: "./footer-bar.component.html",
  styleUrls: ["./footer-bar.component.scss"],
})
export class FooterBarComponent {
  thisYear = format(new Date(), "yyyy");
  version = this.configService.version;

  constructor(private configService: ConfigService) {}
}
