import { Injectable, Inject } from "@angular/core";
import { CommonService } from "./common/common.service";
import { EventBusService, EventsEnum } from "./common/event-bus.service";

@Injectable({
  providedIn: "root",
})
export class GlobalDataService {
  private _languageValue: string = "en-US";
  public minuteOffset: number;
  public isMobile: boolean;
  public whiteMailCampaignId: number;

  public get languageValue(): string {
    return this._languageValue;
  }
  public set languageValue(value: string) {
    this._languageValue = value;
    this._events.emit({
      name: EventsEnum.LanguageChanged,
      data: this._languageValue,
    });
  }
  constructor(private _events: EventBusService) {
    this.minuteOffset = 0;
    this.isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|windows phone|palm/i.test(
      navigator.userAgent.toLowerCase()
    );
  }
}
