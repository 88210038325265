<div class="row mt-minus-15">
    <div class="col-sm-12">
      <div class="well">
        <div class="row">
          <div class="col-sm-12">
              <div class="form-group">
                  <label for="comment">{{'ADDITIONAL_COMMENTS' | translate}}</label><br />
                  <textarea id="comment" name="comment" maxlength="220" rows="3"
                    [(ngModel)]="donorEditCommon.donor.comment" class="form-control"
                    placeholder="{{'ADDITIONAL_COMMENTS_PLACEHOLDER' | translate}}"
                  ></textarea>
                  <span *ngIf="donorEditCommon.donor.comment?.length >= 220"
                    class="help-block error-msg">Characters limit for this input was reached.</span>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>