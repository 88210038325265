import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { LocalStorageService } from "../common/local-storage.service";
import { LoggerService } from "../common/logger.service";
import { CsrService } from "../csr/csr.service";
import { ICampaignQuestionsBindingModel, IHospitalBindingModel } from "../domain/types";
import { RepositoryDonorService } from "../services/repository-donor.service";
import { DataService } from "../services/data.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { Observable } from "rxjs/internal/Observable";
import { forkJoin, Subscription } from "rxjs";
import { BaseComponent } from "../base.component";

@Component({
  selector: 'app-drtv-lower-section',
  templateUrl: './drtv-lower-section.component.html',
  styleUrls: ['./drtv-lower-section.component.scss']
})
export class DrtvLowerSectionComponent extends BaseComponent {

  @ViewChild("faqs") faqs: ElementRef;

  campaignId: string;
  private _hospitalId: string;
  private _paramsSubscription: Subscription;

  campaignQuestions: ICampaignQuestionsBindingModel[] = [];

  donationProcessed: boolean = false;

  constructor(
    private _csrService: CsrService,
    private _localStorageService: LocalStorageService,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _loggerService: LoggerService,
    private _dataService: DataService,
    private _donorService: RepositoryDonorService,
    private _googleAnalytics: GoogleAnalyticsService
  ) {
    super();
  }

  protected _afterComponentInit(): void {
    this._paramsSubscription = this._route.paramMap.pipe(
      switchMap((paramsMap) => {
        this.campaignId = paramsMap.get("cid");
        this._hospitalId = paramsMap.get("hid");

        this._csrService.initialize();

        this._csrService.setCampaignId(Number(this.campaignId));
        this._localStorageService.set(
          "lastCampaignId",
          this._csrService.getCampaignId()
        );

        if (this._hospitalId) {
          this._csrService.setHospitalId(
            Number(this._hospitalId)
          );
          this._localStorageService.set(
            "lastHospitalId",
            this._csrService.getHospitalId()
          );
        } else {
          this._csrService.setHospitalId(0);
          this._localStorageService.set("lastHospitalId", 0);
        }

        return this._getCampaignForCsr();
      }),
      switchMap((_) => {
        let requests: Observable<any>[] = [];
        if (this._csrService.getHospitalId() !== 0) {
          requests.push(this._getHospital());
        }

        requests.push(this._loadFAQs());

        return forkJoin(requests);
      })
    ).subscribe();

    this.addSubscription(
      this._commonService.donationProcessed$.subscribe(value => {
        this.donationProcessed = value;
      })
    )
  }

  protected _afterComponentDestroy(): void {
    this._paramsSubscription?.unsubscribe();
  }

  scrollTo(e: Event, id: string) {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
    if(this.donationProcessed) {
      this._commonService.showConfirmCreditCardECheckPaymentModal(true);
    }
  }

  scrollToFaqs(e: Event) {
    e.preventDefault();
    this.faqs.nativeElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  gaDonateClick(eventCategory: string) {
    this._googleAnalytics.customEventEmitter({
      eventName: "donate_button",
      eventCategory,
      eventAction: "click",
  })};

  private _getHospital(): Observable<IHospitalBindingModel> {
    return this._dataService.getHospital(this._csrService.getHospitalId()).pipe(
      tap((hospital) => {
        this._csrService.setHospitalName(hospital.hospitalName);
      }),
      catchError((error) => {
        const htmlMessage = this._commonService.jsonMessage(error);

        this._loggerService.logError({
          message: `
                  Could not get the Hospital
                  <br />
                  ${htmlMessage}
                `,
        });

        throw error;
      })
    );
  }

  private _getCampaignForCsr() {
    const campaignId = this._csrService.getCampaignId();

    return this._dataService.getCampaignForCsr(campaignId).pipe(
      catchError((error) => {
        const htmlMessage = this._commonService.jsonMessage(error);
        this._loggerService.logError({
          message: `
                  Could not get the Campaign
                  <br />
                  ${htmlMessage}
                `,
        });

        throw error;
      })
    );
  }

  private _loadFAQs(): Observable<ICampaignQuestionsBindingModel[]> {
    return this._donorService.getFAQ(this._csrService.getCampaignId())
      .pipe(
        tap((results) => {
          if (results.length > 0) {
            this.campaignQuestions = results;
          }
        }),
        catchError((error) => {
          const htmlMessage = this._commonService.jsonMessage(error);
          this._loggerService.logError({
            message: `
                    There was an error trying to get the campaign questions
                    <br />
                    ${htmlMessage}
                  `,
          });

          throw error;
        })
      );
  }

}
