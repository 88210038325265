import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "drtv-thankyou",
  templateUrl: "./drtv.thankyou.component.html",
  styleUrls: ["./drtv.thankyou.component.scss"]
})
export class DrtvThankyouMessageComponent {

  @Output() onPageRefresh: EventEmitter<boolean> = new EventEmitter();

  refreshPage(event) {
    event.preventDefault();
    this.onPageRefresh.emit(true);
  }
}