import { Component, OnInit } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: "app-not-authorized",
  templateUrl: "./not-authorized.component.html",
  styleUrls: ["./not-authorized.component.scss"],
})
export class NotAuthorizedComponent implements OnInit {
  constructor(private _commonService: CommonService) {}

  ngOnInit(): void {
    this._commonService
      .activateController([], "Not Authorized")
      .then((response) => response);
  }
}
