<div class="row" style="margin-bottom: 20px">
    <div class="col-sm-12">
        <div class="well" style="border: solid 1px #ccc; box-shadow: 0 0 5px 2px #ddd">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left" [class.has-success]="firstname.valid && (form.submitted || firstname.dirty || firstname.touched)" 
                        [class.has-error]="firstname.invalid && (form.submitted || firstname.dirty || firstname.touched)">
                        <label for="firstName">{{'FIRST_NAME' | translate}}</label><br />
                        <input type="text" id="firstName" name="firstName" #firstname="ngModel" maxlength="50"
                            [(ngModel)]="donorEditCommon.donor.firstName" class="form-control form-control-required"
                            required (input)="onNameChange.emit()" />
                        <span *ngIf="firstname.invalid && (form.submitted || firstname.dirty || firstname.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left" [class.has-success]="lastname.valid && (form.submitted || lastname.dirty || lastname.touched)" 
                        [class.has-error]="lastname.invalid && (form.submitted || lastname.dirty || lastname.touched)">
                        <label for="lastName">{{'LAST_NAME' | translate}}</label><br />
                        <input type="text" id="lastName" name="lastName" #lastname="ngModel" maxlength="50"
                            [(ngModel)]="donorEditCommon.donor.lastName" class="form-control form-control-required"
                            required (input)="onNameChange.emit()" />
                        <span *ngIf="lastname.invalid && (form.submitted || lastname.dirty || lastname.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left" [class.has-success]="email.valid && (form.submitted || email.dirty || email.touched)" 
                    [class.has-error]="email.invalid && (form.submitted || email.dirty || email.touched)">
                        <label for="email">{{'EMAIL' | translate}}</label><br />
                        <input type="email" id="email" name="email" #email="ngModel" maxlength="128" required [email]="true"
                            [(ngModel)]="donorEditCommon.donor.email" class="form-control form-control-required" />
                        <span *ngIf="email.invalid && email.errors?.required && (form.submitted || email.dirty || email.touched)" class="help-block has-error error-msg">
                          This field is required
                        </span>
                        <span *ngIf="email.invalid && email.errors?.email && (form.submitted || email.dirty || email.touched)" class="help-block has-error error-msg">
                          Please enter a valid email address
                        </span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group text-left" [class.has-success]="telephone.valid && (form.submitted || telephone.dirty || telephone.touched)" 
                        [class.has-error]="telephone.invalid && (form.submitted || telephone.dirty || telephone.touched)">
                        <label for="telephone">{{'TELEPHONE' | translate}}</label><br />
                        <input type="tel" id="telephone" name="telephone" #telephone="ngModel"
                            required minlength="7" maxlength="14" pattern="^[0-9]+$" [(ngModel)]="donorEditCommon.donor.telephone"
                            class="form-control form-control-required" />
                        <span *ngIf="telephone.invalid && telephone.errors?.required && (form.submitted || telephone.dirty || telephone.touched)"
                            class="help-block has-error error-msg">This field is required</span>
                        <span *ngIf="telephone.invalid && (telephone.errors?.pattern || telephone.errors?.minlength) && (form.submitted || telephone.dirty || telephone.touched)"
                            class="help-block has-error error-msg">Please enter at least 7 characters</span>
                        <span *ngIf="telephone.value?.length === 14 && (form.submitted || telephone.dirty || telephone.touched)"
                            class="help-block has-error error-msg">Characters limit for this input was reached.</span>
                        <span *ngIf="telephone.invalid && telephone.errors?.pattern && (form.submitted || telephone.dirty || telephone.touched)"
                            class="help-block has-error error-msg">Only numbers are allowed</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-sm-6">
                    <div class="form-group text-left" [class.has-success]="postalcode.valid && (form.submitted || postalcode.dirty || postalcode.touched)" 
                        [class.has-error]="postalcode.invalid && (form.submitted || postalcode.dirty || postalcode.touched)">
                        <label for="postalCode1">{{'POSTAL_CODE' | translate}}</label>
                        <div class="input-group" style="margin-top: 10px">
                            <input type="text" id="postalCode1" name="postalCode1" #postalcode="ngModel" maxlength="15" required
                                [(ngModel)]="donorEditCommon.donor.postalCode"
                                class="form-control form-control-required" (blur)="donorEditCommon.lookUpZipBlur(donorEditCommon.donor.postalCode); donorEditCommon.getLocalizedHospital(donorEditCommon.donor.postalCode)"
                                style="margin-top: 0 !important" />
                            <span *ngIf="donorEditCommon.lookingUpZip" class="input-group-addon">
                                <i class="fa fa-spin fa-spinner text-warning"></i>
                            </span>
                            <span *ngIf="!donorEditCommon.lookingUpZip" class="input-group-addon">
                                <i class="glyphicon glyphicon-search"></i>
                            </span>
                        </div>
                        <span *ngIf="postalcode.invalid && (form.submitted || postalcode.dirty || postalcode.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group text-left" [class.has-success]="address1.valid && (form.submitted || address1.dirty || address1.touched)"
                        [class.has-error]="address1.invalid && (form.submitted || address1.dirty || address1.touched)">
                        <label for="address1">{{'ADDRESS_1' | translate}}</label><br />
                        <input type="text" id="address1" name="address1" #address1="ngModel" maxlength="50"
                            [(ngModel)]="donorEditCommon.donor.address1" class="form-control form-control-required"
                            required />
                        <span *ngIf="address1.invalid && (form.submitted || address1.dirty || address1.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group text-left" [class.has-success]="address2.valid && (form.submitted || address2.dirty || address2.touched)" 
                        [class.has-error]="address2.invalid && (form.submitted || address2.dirty || address2.touched)">
                        <label for="address2" class="optional">{{'ADDRESS_2' | translate}}</label><br />
                        <input type="text" id="address2" name="address2" #address2="ngModel" maxlength="50"
                            [(ngModel)]="donorEditCommon.donor.address2" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-sm-6">
                    <div class="form-group text-left" [class.has-success]="city.valid && (form.submitted || city.dirty || city.touched)" 
                        [class.has-error]="city.invalid && (form.submitted || city.dirty || city.touched)">
                        <label for="city">{{'CITY' | translate}}</label><br />
                        <input type="text" id="city" name="city" maxlength="50" #city="ngModel" [(ngModel)]="donorEditCommon.donor.city"
                            class="form-control form-control-required" required />
                        <span *ngIf="city.invalid && (form.submitted || city.dirty || city.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
                <div class="col-sm-12 col-sm-6">
                    <div class="form-group text-left" [class.has-success]="stateId.valid && (form.submitted || stateId.dirty || stateId.touched)" 
                        [class.has-error]="stateId.invalid && (form.submitted || stateId.dirty || stateId.touched)">
                        <label for="stateId">{{'STATE' | translate}}</label><br />
                        <select class="form-control form-control-required" style="margin-top: 10px" id="stateId" name="stateId" #stateId="ngModel"
                            required [(ngModel)]="donorEditCommon.donor.stateId">
                            <option value="">--Select State--</option>
                            <option *ngFor="let ctype of donorEditCommon.states" [ngValue]="ctype.value">{{ctype.label}}</option>
                        </select>
                        <span *ngIf="stateId.invalid && (stateId.dirty || stateId.touched)" class="help-block has-error error-msg">This field is required</span>
                    </div>
                </div>
            </div>

            <div style="clear: both;">&nbsp;&nbsp;&nbsp;</div>
        </div>
    </div>
</div>
