export enum BatchItemTypeEnum {
  CreditCard = 1,
  Check = 2,
  Cash = 4,
  MoneyOrder = 8,
}

export enum BatchUploadJobsJobTypesEnum {
  BulkMerge = 0,
  BulkUpdate = 1,
  MoveCampaign = 2,
}

export enum BatchUploadJobsStatusEnum {
  Waiting = 0,
  Processing = 1,
  FinishedNoErrors = 2,
  FinishedErrors = 3,
}

export enum CampaignTypeEnum {
  Radiothon = 1,
  Mail = 2,
  WhiteMail = 3,
  Acknowledgment = 4,
  MainAcquisition = 5,
  Pageant = 6,
  Auction = 7,
  DirectResponseTV = 8
}

export enum DonorSortBy {
  FirstName = 0,
  LastName = 1,
  WeScore = 2,
  WeGiftCapacity = 3,
}

export enum SortOrderEnum {
  Ascending = 0,
  Descending = 1,
}

export enum TransactionTypeEnum {
  CreditCard = 1,
  ECheck = 2,
  Pledge = 4,
  DirectDebitPledge = 5,
  StandingOrder = 7,
  Check = 8,
  DirectDebitPendingForm = 9,
  PayPalIpn = 11,
  Cash = 16,
  CreditCardCashiering = 32,
  MoneyOrder = 64,
  DirectDebitFromReturned = 70,
  DirectDebitInitialSubmission = 71,
  DirectDebitTransaction = 72,
  DirectDebitRejection = 73,
  DirectDebitCreditRefundReversal = 74,
  DirectDebitRefundNsf = 75,
  Venmo = 76,
  ApplePay = 77,
  PayPal = 78,
  CreditCardChargeback = 128,
  ECheckChargeBackCredit = 256,
  CheckReturned = 512,
  CheckReturnedNsf = 1024,
  CreditCardManualRefund = 2048,
  ECheckManualRefund = 4096,
  ECheckSystemRefund = 8192,
  CreditCardCredit = 16384,
}

export enum ChargeFrequencyEnum {
  OneTime = 1,
  Monthly = 2,
  Quarterly = 3,
  Yearly = 4,
  SemiAnnual = 5,
}

export enum DonorCommentFilterByEnum {
  All = 0,
  User = 1,
  System = 2,
}

export enum FailedTransactionInvestigationEnum {
  Open = 1,
  NoAnswer = 2,
  SendNotification = 25,
  SystemSendNotification = 95,
  Resolved = 96,
  ClosedBySystem = 97,
  DontRetryAgain = 98,
  TransactionProcessed = 99,
}

export enum CreditCardTypeEnum {
  Mastercard = 1,
  Visa = 2,
  Amex = 4,
  Discover = 16,
}
