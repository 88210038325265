<nav class="drtv-nav" [style.position]="sticky ? 'sticky' : 'static'">
  <header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="drtv-nav__flex">
            <a
              [routerLink]="
                '/helpkidstoday/' + this.campaignId + '/' + this.hospitalId
              "
              class="logo-link"
              title="Children's Miracle Network"
            >
              <img
                src="https://resources.childrensmiraclenetworkhospitals.org/cdn/images/cmnh-logos/CMNH_Logo_Primary_RGB.svg"
                alt="CMN Hospitals Logo"
              />
            </a>

            <button
              class="red-btn nav-donation"
              title="Donate Now"
              (click)="scrollToDonate($event)"
              >Donate Now</button
            >
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="call-bar">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <p>
            You can also donate at
            <br class="visible-xs"> <a href="{{ 'tel:' + this.phoneNumber }}" title="{{this.phoneNumber}}">{{ this.phoneNumber }}</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</nav>
