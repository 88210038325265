import { ChargeFrequencyEnum, TransactionTypeEnum } from "./enums";
import {
  IDonorBindingModel,
  IDonorTransactionBindingModel,
  IWealthEngineReceiveModel,
} from "./types";

export class DonorBindingModel implements IDonorBindingModel {
  donorId: number;
  legacyId: string;
  campaignId: number;
  languageValue: string;
  salutation: string;
  letterSalutation: string;
  addressee: string;
  firstName: string;
  lastName: string;
  spouseFirstName: string;
  spouseLastName: string;
  companyName: string;
  cmnhHospitalId: number;
  cmnhHospitalName: string;
  address1: string;
  address2: string;
  city: string;
  stateId: number;
  stateAbrev: string;
  state: string;
  postalCode: string;
  telephone: string;
  email: string;
  updateKey: number;
  savedUserId: string;
  savedDateTime: Date;
  createdDateTime: Date;
  activeDonor: boolean;
  doNotCall: boolean;
  telephoneType: number;
  sendAppealsYn: boolean;
  sendAppealsTypeId: number;
  suffix: string;
  middleName: string;
  comment: string;
  ckcDonorId: number;
  cmnDonorId: number;
  propToGiveScoreFirstDigit: number;
  propToGiveDescription: string;
  giftCapacityRating: number;
  lastWeSearchDate: Date;
  pdfId: string;
  hospitalId: number;
  wealthEngineReceiveData: IWealthEngineReceiveModel;
  donorSeed: string;
  languageName: string;
  sendAppealTypeDescription: string;
  constructor() {
    this.donorId = 0;
    this.updateKey = 0;
    this.languageValue = "en-US";
    this.activeDonor = true;
    this.doNotCall = false;
    this.sendAppealsYn = true;
    this.sendAppealsTypeId = 0;
  }
}
export class DonorTransactionBindingModel
  implements IDonorTransactionBindingModel {
  abaNumber: string;
  accountPersonName: string;
  authCode: string;
  bankAccountNumber: string;
  bankAccountType: string;
  bankName: string;
  campaignId: number;
  chargeFrequency: ChargeFrequencyEnum;
  cmnhHospitalId: number;
  cmnhHospitalName: string;
  creditCard: string;
  creditCardType: number;
  cvv: string;
  dayToCharge: number;
  displayInDash: boolean;
  donationAmount: number;
  donationSourceId: number;
  donorAppealId: number;
  donorId: number;
  donorReferenceNumber: number;
  donorSeed: string;
  donorTransactionId: number;
  expirationMonth: number;
  expirationYear: number;
  hospitalId: number;
  isCheckingAccount: boolean;
  monthToTerminate: number;
  nonce: string;
  phoneDonationYn: boolean;
  processRefNo: string;
  radioStationNameId: number;
  recurringTransactionId: number;
  responseMsg: string;
  resultCode: number;
  transactionType: TransactionTypeEnum;
  yearToTerminate: number;
  deviceData: string;
  constructor() {
    this.displayInDash = true;
    this.chargeFrequency = ChargeFrequencyEnum.OneTime;
    this.isCheckingAccount = true; //set default to checking
    this.accountPersonName = "";
  }
}

export class ClientTokenWithProfileId {
 token: string;
 profileId: string;
}