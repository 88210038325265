import { Component, OnInit } from "@angular/core";
import { format } from "date-fns";

@Component({
  selector: "app-drtv-footer",
  templateUrl: "./drtv-footer.component.html",
  styleUrls: ["./drtv-footer.component.scss"],
})
export class DrtvFooterComponent implements OnInit {
  currentYear = format(new Date(), "yyyy");

  constructor() {}

  ngOnInit(): void {}
}
