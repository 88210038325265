import { Component, OnInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { CommonService } from "./common/common.service";
import { EventBusService, EventsEnum } from "./common/event-bus.service";
import { LanguageService } from "./common/language.service";
import { LoggerService } from "./common/logger.service";
import { ILookupModelString } from "./domain/types";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "My Gift For Kids";

  routeData: any;
  showSplash = true;
  hideHeader = false;
  drtv = false;
  controllerId = "shell";
  languages: ILookupModelString[];
  languageValue = "en-US";
  isBusy = true;

  constructor(
    private events: EventBusService,
    private commonService: CommonService,
    private languageService: LanguageService,
    private _loggerService: LoggerService,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _renderer2: Renderer2
  ) {}

  ngOnInit() {
    this.router.events.subscribe((routerEvent: RouterEvent): void => {
      if (routerEvent instanceof NavigationStart) {
        this.toggleSpinner(true);

        // Hide the header and footer if the path matches drtv
        if (routerEvent.url.includes("helpkidstoday")) {
          this.hideHeader = true;
          this.drtv = true;
        } else {
          this.hideHeader = false;
          this.drtv = false;
        }
      } else if (
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel
      ) {
        this.toggleSpinner(false);
      } else if (routerEvent instanceof NavigationEnd) {
        this.toggleSpinner(false);

        // @ts-ignore
        if (typeof gtag === "function") {
          const gaConfig: any = { page_path: routerEvent.urlAfterRedirects };

          if (!environment.production) {
            gaConfig.debug_mode = true;
          }
          else {
            // @ts-ignore
            gtag("config", "AW-844820968", gaConfig);
          }

          // @ts-ignore
          gtag("config", "UA-4159481-72", gaConfig);
          // @ts-ignore
          gtag("config", "G-KLG1FHLBZF", gaConfig);
        }
      }
    });

    this.setPageTitle();

    this.events.on(EventsEnum.RouteChanged).subscribe((event) => {
      this.routeData = event.data;

      this.hideHeader =
        this.routeData?.settings?.errorPage ||
        this.routeData?.settings?.dialogPage
          ? true
          : false;
    });

    this.events
      .on(EventsEnum.LanguageChanged)
      .subscribe(() => (this.languageValue = this.languageService.language));

    this.commonService
      .activateController([this._getLanguages()], this.controllerId)
      .then(() => {
        this.showSplash = false;
        this.languageService.getTranslations(this.languageValue);
        this.toggleSpinner(false);
      });
  }

  private loadHavasScript() {
    const scriptId = "havas-tag";
    // Let's not reload things if the script already exists
    const scriptExists = document.getElementById(scriptId);

    if (!scriptExists && environment.production) {
      const script = this._renderer2.createElement("script");
      script.src = "https://tag.havasedge.com/js/evt.js";
      script.async = true;
      script.id = scriptId;
      script.setAttribute(
        "data-evtGuid",
        "5cf27ba5-9ea8-4014-99ea-ec775d2a8e7e"
      );
      script.setAttribute(
        "data-trkGuid",
        "54074f3e-67e1-43ef-9c70-cb4bf0c66302"
      );
      this._renderer2.appendChild(document.head, script);
    }
  }

  private loadGoogleTagManager(id: string, devSrc: string, prodSrc: string) {
    const scriptId = id;
    const scriptExists = document.getElementById(scriptId);

    if (!scriptExists) {
      let src = devSrc;

    if (environment.production) {
        src = prodSrc;
    }

    const script = this._renderer2.createElement("script");

    script.id = scriptId;
    script.src = src;
    script.async = true;
    this._renderer2.appendChild(document.head, script);
    }
  }

  private setPageTitle() {
    const appTitle = this._titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this._activatedRoute.firstChild;
          if (child.snapshot.data["title"]) {
            return child.snapshot.data["title"];
          }

          return appTitle;
        })
      )
      .subscribe((newTitle: string) => {
        this._titleService.setTitle(newTitle);
      });
  }

  private _getLanguages = () => {
    this.languageService
      .getLanguages()
      .then((result) => (this.languages = result))
      .catch((error) => {
        const htmlMessage = this.commonService.jsonMessage(error);

        this._loggerService.logError({
          message: `
            Could not get the list of Languages
            <br />
            ${htmlMessage}
          `,
        });
      });
  };

  languageChangedFromDropDown() {
    this.languageService.language = this.languageValue;
    this.events.emit({
      name: EventsEnum.LanguageChanged,
      data: this.languageValue,
    });
  }

  toggleSpinner(show: boolean) {
    this.events.emit({ name: EventsEnum.SpinnerToggle, data: show });
  }
}
