import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DonationFrequencyService {
  chargeFrequency: string;
  dayOfMonth: number;
  giftTerminationMonth: any;
  giftTerminationYear: any;

  constructor() {}
}
