import { EventEmitter, Injectable, Output } from "@angular/core";
import escapeHtml from "escape-html";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

import { ConfigService } from "../config.service";
import { ChargeFrequencyEnum } from "../domain/enums";
import { GlobalDataService } from "../global-data.service";
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  pageTitle = "";
  pageSubTitle = "";
  $location: any;
  @Output() shuffelContact: EventEmitter<Object> = new EventEmitter();
  @Output() shuffelAbout: EventEmitter<Object> = new EventEmitter();
  @Output() shuffelHome: EventEmitter<Object> = new EventEmitter();

  private _donationProcessed = new Subject<boolean>();
  private _showConfirmCreditCardECheckPaymentModal = new Subject<boolean>();

  /** Observable */
  donationProcessed$ = this._donationProcessed.asObservable();
  showConfirmCreditCardECheckPaymentModal$ = this._showConfirmCreditCardECheckPaymentModal.asObservable();

  constructor(
    public configService: ConfigService,
    public globalData: GlobalDataService,
    public logger: LoggerService,
    public toast: ToastrService
  ) {}

  showModal(element, options = {}) {
    element.modal("show");
  }

  hideModal(element, data = {}) {
    element.modal("hide");
  }

  public underConstructionDialog() {
    const element = $("#app-construction-modal");

    this.showModal(element);
  }

  getFrequencyText(chargeFrequency: ChargeFrequencyEnum): string {
    switch (chargeFrequency) {
      case ChargeFrequencyEnum.OneTime:
        return "One Time";
      case ChargeFrequencyEnum.Monthly:
        return "Monthly";
      case ChargeFrequencyEnum.Quarterly:
        return "Quarterly";
      case ChargeFrequencyEnum.SemiAnnual:
        return "Semi Annual";
      case ChargeFrequencyEnum.Yearly:
        return "Yearly";
      default:
        return "";
    }
  }

  saveSelectedMarketId(arg0: null) {
    console.log("common.saveSelectedMarketId called");
  }

  executeMultiplePromises(promises: any[]) {
    console.log("common.executeMultiplePromises called");
  }

  activateController = (
    promises: any[],
    controllerId: string
  ): Promise<any> => {
    return Promise.all(promises)
      .then((resolves) => {
        var data = { controllerId: controllerId };

        return resolves;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  serviceUrl(serviceName): string {
    return `${this.configService.apiUrl()}${serviceName}/`;
  }

  crToBr = (value: string): string => {
    value = escapeHtml(value);
    value = value.trim();
    value = value.replace(/\r?\n/g, "<br />\n");
    return value;
  };

  jsonMessage = (error: any): string => {
    var message = "";

    if (error.data) {
      if (error.data.exceptionMessage) {
        message = error.data.exceptionMessage;
        if (error.data.stackTrace) {
          message += "<br />" + this.crToBr(error.data.stackTrace);
        }
      } else if (error.data.messageDetail) {
        message = error.data.messageDetail;
      } else if (error.data.message) {
        if (error.data.modelState) {
          var items;
          message = "";
          for (var key in error.data.modelState) {
            items = error.data.modelState[key];

            if (items.length) {
              for (var i = 0; i < items.length; i++) {
                message += "<br />" + escapeHtml(items[i]);
              }
            }
          }
        } else {
          message = error.data.message;
        }
      } else if (error.data.error_description) {
        message = error.data.error_description;
      }
      else {
        message = error.data;
      }
    }
    var message2 = "";
    if (error.status) {
      message2 = error.status + " " + error.statusText;
    } else if (error.statusText) {
      message2 = error.statusText;
    } else if (error.description) {
      message2 = error.description;
    } else if (error.error_description) {
      message2 = error.error_description;
    }

    if (message == "" && message2 == "") {
      message = "unknown";
    } else if (message === "") {
      message = message2;
    }

    return message;
  };

  aboutShuffel() {
    this.shuffelAbout.emit()
  }
  contactShuffel() {
    this.shuffelContact.emit()
  }
  homeShuffel() {
    this.shuffelHome.emit()
  }
  donationProcessed(value: boolean) {
    this._donationProcessed.next(value);
  }
  showConfirmCreditCardECheckPaymentModal(value: boolean) {
    this._showConfirmCreditCardECheckPaymentModal.next(value);
  }
}
