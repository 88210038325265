<section class="animation-effects" [ngClass]="{'animate' : showAnimation}">
  <div class="pull-right">
    <img
      class="img-responsive"
      src="assets/images/helpHeaderImage.jpg"
      alt="Help the Children"
    />
  </div>

  <p></p>
  <p>
    Since 1983, Children’s Miracle Network Hospitals has raised more than $5
    billion—most of it $1 at a time—for 170 children’s hospitals across the
    United States and Canada, which, in turn, use the money where it’s needed
    the most. These donations have gone to support research and training,
    purchase equipment, and pay for uncompensated care, all to save and improve
    the lives of as many children as possible.
  </p>
  <p>
    <strong>Our Mission:</strong>
    We increase funds and awareness for local children’s hospitals.
  </p>
  <p>
    <strong>Our Vision:</strong>
    Together we save kids’ lives.
  </p>
  <p>
    Right now there’s a Children’s Miracle Network Hospital working to save the
    lives of kids in your community. In fact, 62 children enter a Children’s
    Miracle Network Hospital for treatment every minute — that's one child every
    second. Some are battling cancer. Some are suffering from a traumatic
    injury. Others require constant care because they were born too early, or
    with a genetic disease. Regardless of why the kids are there, Children’s
    Miracle Network Hospitals always have their doors open.
  </p>
  <div class="row">
    <div class="col-sm-12">
      <h3>Children We Help</h3>
      <div class="pull-right">
        <img
          class="img-responsive"
          src="assets/images/2013-Champions.jpg"
          alt="Children We Help Image"
        />
      </div>
      <p>
        62 children enter a Children's Miracle Network Hospital for treatment
        every minute. Whether they suffer from common childhood afflictions like
        asthma and broken bones, or fight bigger challenges like birth defects
        or cancer, Children’s Miracle Network Hospitals provide comfort,
        treatment and hope to millions of sick kids. In fact, Children’s Miracle
        Network Hospitals treat one in 10 children in North America each year.
      </p>
      <p>
        These kids aren’t in faraway countries or from opposite sides of the
        nations - they’re in your community. They may be your own children.
      </p>
      <p>
        Every year, we identify a child with a remarkable medical story from
        each state. These “Champions” serve as the face for the millions of
        children treated at our hospitals every year. Our Champions and their
        families travel to Washington, D.C., and Orlando, Fla. on an official
        Ambassador Tour to highlight the vital work of children’s hospitals.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <p>
        <strong>Site Information</strong>
      </p>
      <p>Version: {{ version }}</p>
      <p>Date Released: {{ releaseDate | date }}</p>
    </div>
  </div>
</section>
