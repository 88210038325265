import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { CommonService } from "../common/common.service";

import {
  IDonorBindingModel,
  IDonorTransactionBindingModel,
  ICampaignQuestionsBindingModel,
  IAddressDataBinding
} from "../domain/types";

@Injectable({
  providedIn: "root",
})
export class RepositoryDonorService {
  saveInHonorGiftNotification(data: any) {
    return this.http.post(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.donor
      )}SaveInHonorGiftNotification`,
      data
    );
  }
  saveTransaction(transaction: IDonorTransactionBindingModel) {
    return this.http.post(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.donor
      )}SaveSplashTransaction`,
      transaction
    );
  }
  getBankFromRouting(abaNumber: any) {
    const url = `${this._commonService.serviceUrl(this._commonService.configService.apiServices.donor)}LookupBankName/${abaNumber}`;
    return this.http.get(url, { responseType: 'text' });
  }
  lookupZip(postalCode: string) {
    const url = `${this._commonService.serviceUrl(this._commonService.configService.apiServices.donor)}LookupZip/${postalCode}`;
    return this.http.get<IAddressDataBinding>(url);
  }
  verifyAddress(donor: IDonorBindingModel) {
    return this.http.post(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.donor
      )}VerifyAddress`,
      donor
    );
  }

  save(donor: IDonorBindingModel, token: string) {
    return this.http.post(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.donor
      )}Save/splash/${token}`,
      donor
    );
  }

  getFAQ(campaignId: number, languageValue: string = "en-US", webOnlyQuestions: boolean = true){
    return this.http.get<ICampaignQuestionsBindingModel[]>(
      `${this._commonService.serviceUrl(
        this._commonService.configService.apiServices.campaignQuestions
      )}CampaignQuestions/${campaignId}/${languageValue}/${webOnlyQuestions}`,
    );
  }

  constructor(
    private http: HttpClient,
    private _commonService: CommonService
  ) {}
}
