import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";
import { ConfigService } from "../config.service";
import { ClientTokenWithProfileId } from "../domain/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RepositoryBraintreeService {
  constructor(
    private _common: CommonService,
    private _config: ConfigService,
    private _http: HttpClient
  ) {}

  public getClientToken(includeProfileId: boolean = false): Observable<ClientTokenWithProfileId> {
    let url = `${this._common.serviceUrl(this._config.apiServices.braintree)}ClientToken`;
    if (includeProfileId === true)
      url += `/${includeProfileId}`;

    return this._http.get<ClientTokenWithProfileId>(url);
  }
}
