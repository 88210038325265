import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { IHospitalCMNHModel } from "../domain/types";
import { DataService } from "../services/data.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "app-hospital-search",
  templateUrl: "./hospital-search.component.html",
  styleUrls: ["./hospital-search.component.scss"],
})
export class HospitalSearchComponent implements OnInit {
  @ViewChild("hospitalSearchForm") hospitalSearchForm: NgForm;

  @Input() showTitle = true;
  @Input() largeSearch = false;

  zipCode: string;
  hospital: IHospitalCMNHModel;
  loading = false;
  submitted = false;
  uniqueId = Math.floor(Math.random() * 1000); // A unique id for the label and input. Needed for accessibility

  constructor(
    private _dataService: DataService,
    private _googleAnalytics: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  onKeyPress() {
    this.submitted = false;
  }

  onSubmit() {
    this.loading = true;

    if (this.hospitalSearchForm.invalid) {
      return (this.loading = false);
    }

    this._dataService.getHospitalByPostal(this.zipCode).subscribe(
      (hospitals) => {
        this.loading = false;
        this.submitted = true;
        this.hospital = hospitals[0];

        this._googleAnalytics.customEventEmitter({
          eventName: "hospital_search",
          eventCategory: "hospital_search",
          eventAction: "search",
          eventValue: parseInt(this.zipCode),
          eventLabel: this.hospital.displayName,
        });
      },
      (error) => {
        this.loading = false;
        this.submitted = true;
        this.hospital = undefined;
      }
    );
  }
}
