<div class="hospital-search__form" [ngClass]="{ loading: loading, largeSearch: largeSearch }">
  <form #hospitalSearchForm="ngForm" (ngSubmit)="onSubmit()">
    <div *ngIf="loading" class="loader-holder">
      <div class="ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="loader-text">
        <slot></slot>
      </p>
    </div>

    <label [for]="'zipCode' + uniqueId" [ngClass]="{hideTitle: !showTitle}">Please Enter Your Zip Code</label>
    <div class="input-wrap">
      <input
        [id]="'zipCode' + uniqueId"
        [name]="'zipCode' + uniqueId"
        type="search"
        inputmode="numeric"
        required
        minlength="5"
        maxlength="5"
        pattern="[0-9]*"
        [(ngModel)]="zipCode"
        #zipCodeInput="ngModel"
        (keypress)="onKeyPress()"
        [placeholder]="largeSearch ? 'Input Billing Zip Code' : ''"
      />

      <button type="submit">Search</button>
    </div>

    <div
      class="hospital-search__error"
      *ngIf="zipCodeInput.invalid && zipCodeInput.touched"
    >
      <div *ngIf="zipCodeInput.errors?.required; else invalidZip">
        A zip code is required
      </div>
      <ng-template #invalidZip>
        <div>Please enter a valid zip code</div></ng-template
      >
    </div>
  </form>

  <!-- We only want to show this section if the form has been submitted. -->
  <div *ngIf="this.submitted">
    <p
      *ngIf="
        this?.hospital?.displayName || this?.hospital?.hospitalName as name;
        else noHospital
      "
      class="search-result"
    >
      Your donation will benefit <span>{{ name }}</span>.
    </p>
    <ng-template #noHospital
      ><p>
        Sorry, we didn't find a hospital for that search. Please try again.
      </p></ng-template
    >
  </div>
</div>
