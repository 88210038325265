import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { ILookupModelString, ITranslations } from "../domain/types";
import { EventBusService, EventsEnum } from "./event-bus.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public languages = ["en-US", "es-US"];
  public language = "en-US";
  public translations: ITranslations = {};
  private _serviceId = "repositoryLookup";

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private eventsService: EventBusService
  ) {
    this.eventsService.on(EventsEnum.LanguageChanged).subscribe((event) => {
      this.getTranslations(event.data);
    });
  }

  getTranslations(language: string): void {
    const languageEndpoint =
      this.configService.baseUrl +
      this.configService.apiBaseUrl +
      this.configService.apiServices.language +
      `/Get?lang=${language}`;

    this.http.get<ITranslations>(languageEndpoint).subscribe((translations) => {
      this.translations = translations;
    }, console.error);

    this.language = language;
  }

  translate(key: string): string {
    return this.translations[key] || "";
  }

  // this code comes from legacy/app/services/repositoryLookup
  // passed to common.activateController by the shell component
  public getLanguages = (): Promise<ILookupModelString[]> => {
    let cacheKey = this._serviceId + "languages";
    let value: ILookupModelString[] = this.localStorageService.get<
      ILookupModelString[]
    >(cacheKey, undefined);

    if (value) {
      return Promise.resolve(value);
    }

    const languagesEndpoint =
      this.configService.baseUrl +
      this.configService.apiBaseUrl +
      this.configService.apiServices.lookup +
      "/Languages";

    return this.http
      .get(languagesEndpoint)
      .toPromise()
      .then((languages: Promise<ILookupModelString[]>) => {
        this.localStorageService.set(cacheKey, languages, {
          TTL: this.configService.cacheLongTTL,
        });

        console.log("languages", languages);

        return languages;
      });
  };
}
