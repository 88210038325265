import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";
import { LocalStorageService } from "../common/local-storage.service";
import { ChargeFrequencyEnum } from "../domain/enums";

@Injectable({
  providedIn: "root",
})
export class CsrService {
  bindGiftToInHonorInMemory() {
    throw new Error("Method not implemented.");
  }
  private _hospitalId = 0;
  private _hospitalName = "";
  private _pageNumber = 1;
  private _hospitalContactName = "";
  private _hospitalContactPhoneNumber = "";
  private _firstTimeAdditionalHelp = true;
  private _cameFrom = 0;
  private _canLeave = true;
  private _startTime = new Date();
  private _scriptType = 0;
  private _marketId = 0;
  private _noOfRadioStations = 0;
  private _noOfHospitals = 0;
  private _companyName = "";
  private _campaignId: number | null = null;

  public donorFirstName = "";
  public donorLastName = "";
  public donorId = 0;
  public donorReferenceNumber;
  public donorSeed;
  public responseMsg: string;
  public processRefNo: string;
  public authCode: string;
  public resultCode = -1;
  public initialized = false;
  public radioStationId = 0;
  public donationAmount = 0;
  public chargeFrequency = ChargeFrequencyEnum.OneTime;
  donorTransactionId: number;

  constructor(
    private _localStorageService: LocalStorageService,
    private _commonService: CommonService
  ) {
    this._campaignId = this._localStorageService.get("lastCampaignId", 0);
    this._hospitalId = this._localStorageService.get("lastHospitalId", 0);
    this.radioStationId = this._localStorageService.get("lastRadioStationId", 0);
  }

  public initialize() {
    this._hospitalId = 0;
    this._hospitalName = "";
    this._pageNumber = 1;
    this.donorFirstName = "";
    this.donorLastName = "";
    this.donorId = 0;
    this._hospitalContactName = "";
    this._hospitalContactPhoneNumber = "";
    this._firstTimeAdditionalHelp = true;
    this._cameFrom = 0;
    this._canLeave = true;
    this._startTime = new Date();

    this._campaignId = this._localStorageService.get("lastCampaignId", 0);
    this._hospitalId = this._localStorageService.get("lastHospitalId", 0);
    this.radioStationId = this._localStorageService.get("lastRadioStationId", 0);

    this._scriptType = 0;
    this._marketId = 0;
    this._noOfRadioStations = 0;
    this._noOfHospitals = 0;
    this._companyName = "";
    this.donationAmount = 0;
    this.chargeFrequency = ChargeFrequencyEnum.OneTime;
    this.resultCode = -1;
    this._hospitalName = "";
    this._commonService.saveSelectedMarketId(null);
    this.initialized = true;
  }

  getCampaignId() {
    return this._campaignId;
  }

  setCampaignId(id: number | null) {
    this._campaignId = id;
    this._canLeave = false;
  }

  getHospitalId(): number {
    return this._hospitalId;
  }

  setHospitalId(value: number) {
    this._hospitalId = value;
    this._canLeave = false;
  }

  getHospitalName(): string {
    return this._hospitalName;
  }

  setHospitalName(name: string) {
    this._hospitalName = name;
    this._canLeave = false;
  }
}
