import { Component } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CommonService } from '../common/common.service';

@Component({
  selector: 'app-drtv-grid',
  templateUrl: './drtv-grid.component.html',
  styleUrls: ['./drtv-grid.component.scss']
})
export class DrtvGridComponent extends BaseComponent {

  donationProcessed: boolean = false;

  constructor(private _commonService: CommonService) { 
    super();
  }

  protected _afterComponentInit(): void {
    this.addSubscription(
      this._commonService.donationProcessed$.subscribe(value => {
        this.donationProcessed = value;
      })
    )
  }
  protected _afterComponentDestroy(): void {}

  scrollTo(e: Event, id: string) : void {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
    if(this.donationProcessed) {
      this._commonService.showConfirmCreditCardECheckPaymentModal(true);
    }
  }

}
