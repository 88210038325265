import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CommonService } from "../common/common.service";
import { EventBusService, EventsEnum } from "../common/event-bus.service";
import { ConfigService } from "../config.service";

@Component({
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  version = this._configService.version;
  releaseDate = this._configService.dateReleased;
  showAnimation : boolean = false;

  constructor(
    private _configService: ConfigService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _events: EventBusService
  ) {
    this._commonService.shuffelContact.subscribe(()=>{      
      this.showAnimation = true;
    });
    this._commonService.shuffelHome.subscribe(()=>{      
      this.showAnimation = true;
    });
  }

  ngOnInit(): void {
    this._route.data.subscribe((data) => {
      this._events.emit({ name: EventsEnum.RouteChanged, data });
    });

    this._commonService.pageTitle = "About";
    this._commonService.pageSubTitle = "";

    this._commonService
      .activateController([], "about")
      .then((results) => results)
      .catch((error) => console.error(error));
  }
}
