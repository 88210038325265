import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  baseUrl = environment.baseUrl;

  apiBaseUrl = "/api/";

  recaptchaSiteKey = environment.recaptchaSiteKey;

  version = "4.02.200";
  // Month is zero based. So Jan is "0" and Feb = "1"
  dateReleased = new Date(2021, 9, 1);

  apiServices = {
    lookup: "Lookup",
    language: "Language",
    pageScriptManagement: "PageScriptManagement",
    personalStoryManagement: "PersonalStoryManagement",
    donor: "OpenDonorManagement",
    campaign: "CampaignManagement",
    hospital: "OpenHospitalManagement",
    localHospital: "HospitalManagement/FindLocalHospitals",
    radioStationName: "OpenRadioStationNameManagement",
    braintree: "BraintreeManagement",
    campaignQuestions: "CampaignManagement",
  };

  events = {
    controllerActivateSuccess: "controller.activateSuccess",
    spinnerToggle: "spinner.toggle",
    languageChanged: "language.Changed",
    donorUpdated: "donor.updated",
  };
  cacheLongTTL: number = 3600000;

  constructor() {}

  apiUrl(): string {
    return this.baseUrl + this.apiBaseUrl;
  }
}
