<section class="animation-effects" [ngClass]="{ animate: showAnimation }">
  <div class="content">
    <div class="row" *ngIf="!enableDonation">
      <div class="col-sm-12">
        <div class="well well-sm">
          <h4 class="text-danger">{{ "DONATIONS_NOT_ALLOWED" | translate }}</h4>
        </div>
      </div>
    </div>

    <div [hidden]="!showPage">
      <div class="row">
        <div class="col-sm-12">
          <button
            class="btn btn-lg btn-primary center-block"
            [hidden]="!enableDonation"
            [disabled]="!enableDonation"
            (click)="donate()"
          >
            {{ "DONATE" | translate }}
          </button>

          <button
            class="btn btn-lg btn-danger center-block"
            [hidden]="enableDonation"
            [disabled]="enableDonation"
          >
            {{ "DONATIONS_NOT_ALLOWED" | translate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-offset-2 col-sm-10">
          <img
            (error)="toggleImage(false)"
            [src]="imageUrl"
            [hidden]="hideImage"
            alt="campaign image"
            class="img-responsive"
          />
        </div>
      </div>
    </div>
  </div>
</section>
