import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  private events$ = new Subject<any>();

  constructor() {}

  on(event: EventsEnum) {
    return this.events$.pipe(filter((e) => e.name === event));
  }

  emit(event: IEvent) {
    this.events$.next(event);
  }
}

export enum EventsEnum {
  LanguageChanged = "LanguageChanged",
  RouteChanged = "RouteChanged",
  SpinnerToggle = "SpinnerToggle",
  DonorUpdated = "DonorUpdated",
}

export interface IEvent {
  name: EventsEnum;
  data?: any;
}

/*
Simple Event Bus
- components can raise and subscribe to events using the event bus.
- no coupling between components.

- raising:

```js
  const event: IEvent = { name: EventsEnum.RouteChanged, data };
  this.events.emit(event);
```

- subscribing:

```js
routeData: any;
  constructor(private events: EventBusService) {
    this.events.on(EventsEnum.RouteChanged).subscribe((e) => {
      this.routeData = e.data;
    });
  }
```
  - add new events to the EventsEnum. (edited) 
*/
