<div class="row">
  <div class="col-sm-12">
    <div
      class="well"
      style="border: solid 1px #ccc; box-shadow: 0 0 5px 2px #ddd"
    >
      <!-- hasErrors: {{hasErrors}} -->
      <section [class.display-none]="paymentType !== 1" class="col-sm-12">
        <div id="dropin-container"></div>

        <div class="divider">
          <div class="line"></div>
          <div class="divider-text">{{ "OR" | translate }}</div>
          <div class="line"></div>
        </div>

        <button
          type="button"
          class="btn btn-info payment-type"
          (click)="eCheckClicked()"
          [ngClass]="{ active: paymentType === 2 }"
        >
          <i class="fa fa-money"></i
          ><span>{{ "ECHECK_PAYMENT_TYPE" | translate }}</span>
        </button>
      </section>

      <section *ngIf="paymentType === 2" class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h4 class="echeck-title">{{"PAY_WITH_ECHECK" | translate}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div
              class="form-group text-left"
              [ngClass]="
                showError(routingnumber)
                  ? routingnumber.valid
                    ? 'has-success'
                    : 'has-error'
                  : ''
              "
            >
              <label for="routingNumber">{{
                "ROUTING_NUMBER" | translate
              }}</label
              ><br />
              <input
                type="text"
                required
                class="form-control form-control-required"
                id="routingNumber"
                name="routingNumber"
                pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                #routingnumber="ngModel"
                [(ngModel)]="transaction.abaNumber"
                (change)="getBankName()"
              />
              <div
                *ngIf="routingnumber.invalid && showError(routingnumber)"
                class="help-block has-error error-msg"
              >
                <div *ngIf="routingnumber.errors.required">
                  This field is required
                </div>
                <div *ngIf="routingnumber.errors.pattern">
                  Please ensure the routing number is formatted correctly.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div
              class="form-group text-left"
              [ngClass]="
                showError(banknumber)
                  ? banknumber.valid
                    ? 'has-success'
                    : 'has-error'
                  : ''
              "
            >
              <label for="bankNumber">{{ "ACCOUNT" | translate }}</label
              ><br />
              <input
                type="number"
                class="form-control form-control-required"
                id="bankNumber"
                name="bankNumber"
                #banknumber="ngModel"
                [(ngModel)]="transaction.bankAccountNumber"
                pattern="[0-9]*(\.?[0-9]{1,2}$)?"
                required
              />
              <div
                *ngIf="banknumber.invalid && showError(banknumber)"
                class="help-block has-error error-msg"
              >
                <div *ngIf="banknumber.errors.required">
                  This field is required
                </div>
                <div *ngIf="banknumber.errors.pattern">
                  Please ensure the account number is formatted correctly.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div
              class="form-group text-left"
              [ngClass]="
                showError(bankname)
                  ? bankname.valid
                    ? 'has-success'
                    : 'has-error'
                  : ''
              "
            >
              <label for="bankName">{{ "BANK_NAME" | translate }}</label
              ><br />
              <input
                type="text"
                class="form-control form-control-required"
                id="bankName"
                name="bankName"
                #bankname="ngModel"
                [(ngModel)]="transaction.bankName"
                maxlength="100"
                required
              />
              <span
                *ngIf="bankname.invalid && showError(bankname)"
                class="help-block has-error error-msg"
                >This field is required</span
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-group text-left">
              <strong class="hidden-sm hidden-xs">&nbsp;&nbsp;</strong
              ><br class="hidden-sm hidden-xs" />
              <div class="btn-group hidden-xs" style="margin-top: 10px">
                <button
                  type="button"
                  class="btn btn-checkingSaving"
                  [ngClass]="{ active: transaction.isCheckingAccount }"
                  (click)="transaction.isCheckingAccount = true"
                >
                  <i
                    class="fa fa-1-5x"
                    [ngClass]="{
                      'fa-check-square-o': transaction.isCheckingAccount,
                      'fa-square-o': !transaction.isCheckingAccount
                    }"
                  ></i>
                  &nbsp;
                  <strong style="text-transform: unset !important">{{
                    "CHECKING" | translate
                  }}</strong>
                </button>
                <button
                  type="button"
                  class="btn btn-checkingSaving"
                  [ngClass]="{ active: !transaction.isCheckingAccount }"
                  (click)="transaction.isCheckingAccount = false"
                >
                  <i
                    class="fa fa-1-5x"
                    [ngClass]="{
                      'fa-check-square-o': !transaction.isCheckingAccount,
                      'fa-square-o': transaction.isCheckingAccount
                    }"
                  ></i>
                  &nbsp;
                  <strong style="text-transform: unset !important">{{
                    "SAVINGS" | translate
                  }}</strong>
                </button>
              </div>
              <div class="text-center visible-xs" style="margin-top: 10px">
                <button
                  type="button"
                  class="btn btn-checkingSaving"
                  [ngClass]="{ active: transaction.isCheckingAccount }"
                  (click)="transaction.isCheckingAccount = true"
                >
                  <i
                    class="fa fa-1-5x"
                    [ngClass]="{
                      'fa-check-square-o': transaction.isCheckingAccount,
                      'fa-square-o': !transaction.isCheckingAccount
                    }"
                  ></i>
                  &nbsp;
                  <strong style="text-transform: unset !important">{{
                    "CHECKING" | translate
                  }}</strong>
                </button>
                <button
                  type="button"
                  class="btn btn-checkingSaving"
                  [ngClass]="{ active: !transaction.isCheckingAccount }"
                  (click)="transaction.isCheckingAccount = false"
                >
                  <i
                    class="fa fa-1-5x"
                    [ngClass]="{
                      'fa-check-square-o': !transaction.isCheckingAccount,
                      'fa-square-o': transaction.isCheckingAccount
                    }"
                  ></i>
                  &nbsp;
                  <strong style="text-transform: unset !important">{{
                    "SAVINGS" | translate
                  }}</strong>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-top: 10px">
          <img
            src="assets/images/check99.gif"
            alt=""
            style="width: 100%; max-width: 400px"
          />
        </div>

        <button
          type="button"
          class="btn btn-info payment-type secondary"
          (click)="creditCardClicked()"
          [ngClass]="{ active: paymentType === 1 }"
        >
          <span>{{"CHOSE_PAYMENT_TYPE" | translate}}</span>
        </button>
      </section>

      <div style="clear: both">&nbsp;&nbsp;&nbsp;</div>
    </div>
  </div>
</div>
