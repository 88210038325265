<section class="drtv-grid">
  <div class="drtv-grid__item drtv-grid__item--red drtv-grid__image-right">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-5 col-lg-offset-1">
          <div class="drtv-item__content text-center">
            <h2>100% of your donation <span class="p"> goes directly to your local member hospital to</span> <span class="h3"> SUPPORT THEIR MOST URGENT NEEDS</span></h2>

            <button (click)="scrollTo($event, 'hospitalSearch')" class="big-btn">Find your local hospital</button>
          </div>
        </div>
      </div>
    </div>

    <div class="img-container">
      <figcaption class="sr-only">CMN Hospitals Champion</figcaption>
    </div>
  </div>

  <div class="drtv-grid__item drtv-grid__item--yellow drtv-grid__image-left">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-6 col-md-offset-6 col-lg-5 col-lg-offset-6">
          <div class="drtv-item__content text-center">
            <h2><sup>$</sup>19<small>/Month</small> <span class="p">or more, you can create more moments for dress-up, coloring, and movie nights with mom</span></h2>
            <br>
            <button (click)="scrollTo($event, 'donateNow')" class="big-btn" title="Find your local hospital">Give now</button>
            <br>
            <span class="statement">With every monthly gift of $19 or more, you’ll receive a Children’s Miracle Network Hospitals tote bag to publicly show your support for kids in need.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="img-container">
      <figcaption class="sr-only">A cloth bag sitting in a park with a hand-drawn CMN Hospitals logo on it</figcaption>
    </div>
  </div>
</section>
